import Skeleton from "react-loading-skeleton"

const CaseSkeleton = ({ skeletonIndex }) => {


    return (
        <tr class={skeletonIndex % 2 === 0 ? "odd" : 'even'} >
            <td class="sorting_1" style={{ cursor: 'pointer' }}><Skeleton /></td>
            <td style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ marginRight: "10px" }}><Skeleton circle width={40} height={40} /></div>
                <div style={{ flex: 1 }}><Skeleton /></div>
            </td>
            <td><Skeleton /></td>
            <td><Skeleton /></td>
            <td><Skeleton /></td>
            <td><Skeleton /></td>
            <td><Skeleton /></td>
            <td><Skeleton /></td>
            <td><Skeleton /></td>
            <td><Skeleton /></td>
            <td><Skeleton /></td>
            <td><Skeleton /></td>
        </tr>
    )

}

export default CaseSkeleton