import { createContext, useContext, useState } from 'react'
import { templateEditorDeafultFontFamily } from '../../utils/constant'

const TemplateContext = createContext()

export const TemplateProvider = ({ children }) => {
    const [fontFamily, setFontFamily] = useState(templateEditorDeafultFontFamily)

    const value = {
        fontFamily,
        setFontFamily
    }

    return (
        <TemplateContext.Provider value={value}>
            {children}
        </TemplateContext.Provider>
    )
}

export const useTemplateContext = () => useContext(TemplateContext)