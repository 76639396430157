import React from 'react';
import { TemplateFormProvider } from '../TemplateFormContext';

const withTemplateFormContext = (Component, templateType) => {
    return (props) => {
        return (
            <TemplateFormProvider templateType={templateType}>
                <Component {...props} />
            </TemplateFormProvider>
        );
    };
};

export default withTemplateFormContext;
