

import React, { useEffect } from 'react';
import 'react-quill/dist/quill.snow.css';
import '../style.css';
import './template.css';
import DefaultThirdPartyNonPolicyLimitDemand from './DefaultThirdPartyNonPolicyLimitDemand';
import DefaultThirdPartyPolicyLimitDemand from './DefaultThirdPartyPolicyLimitDemand';
import DefaultUIMNonPolicyLimitDemand from './DefaultUIMNonPolicyLimitDemand';
import DefaultUIMPolicyLimitDemand from './DefaultUIMPolicyLimitDemand';
import withContext from '../../hoc/withContext ';
import { TemplateProvider, useTemplateContext } from './TemplateContext';


const Template = () => {
    const { fontFamily } = useTemplateContext()

    useEffect(() => {
        document.documentElement.style.setProperty('--quill-editor-default-font-family', fontFamily);
      }, [fontFamily]);

    return (
        <>
            <div className="container-fluid">
                <div className="col-md-12 mt-5">
                    <div className="tabs-wrap mt-4">
                        <div className="nav nav-pills nav-justified" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                            <button className="nav-link active" id="v-pills-home-tab" data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button" role="tab" aria-controls="v-pills-home" aria-selected="true">Third Party Policy Limit Demand</button>
                            <button className="nav-link" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false">Third Party Non-Policy Limit Demand</button>
                            <button className="nav-link" id="v-pills-messages-tab" data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="button" role="tab" aria-controls="v-pills-messages" aria-selected="false">UIM Policy Limit Demand</button>
                            <button className="nav-link" id="v-pills-settings-tab" data-bs-toggle="pill" data-bs-target="#v-pills-settings" type="button" role="tab" aria-controls="v-pills-settings" aria-selected="false">UIM Non-Policy Limit Demand</button>
                        </div>
                        <div className="tab-content" id="v-pills-tabContent">
                            <div className="tab-pane fade show active" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab" tabIndex="0">
                                <DefaultThirdPartyPolicyLimitDemand />
                            </div>
                            <div className="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab" tabIndex="0">
                                <DefaultThirdPartyNonPolicyLimitDemand />
                            </div>
                            <div className="tab-pane fade" id="v-pills-messages" role="tabpanel" aria-labelledby="v-pills-messages-tab" tabIndex="0">
                                <DefaultUIMPolicyLimitDemand />
                            </div>
                            <div className="tab-pane fade" id="v-pills-settings" role="tabpanel" aria-labelledby="v-pills-settings-tab" tabIndex="0">
                                <DefaultUIMNonPolicyLimitDemand />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
};

export default withContext(Template, TemplateProvider) ;