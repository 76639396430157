import axios from 'axios';
import AppConfig from '../AppConfig';
import Constants from '../Constants';
import { headers } from '.';
class LogoutService {
    static Logout = (payloadData) => {
        
        const url = `${AppConfig.ApiBaseUrl}${Constants.ApiUrl.logout.logout}`;
        return axios.post(url,payloadData,{...headers()})
    }
}

export default LogoutService;