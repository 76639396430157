import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import '../../style.css';
import 'react-datepicker/dist/react-datepicker.css';

const Graph = ({ dataPoints, totalNumCases, graph, MPoints, yAxisLabel= 'Users' }) => {

    const chartRef = useRef();

    useEffect(() => {
        drawChart();
        console.log(dataPoints, MPoints, "Mdata");
    }, [dataPoints, MPoints]);

    const drawChart = () => {
       

        if (graph) {
            const svg = d3.select(chartRef.current);
            svg.selectAll("*").remove();
            const margin = { top: 50, right: 50, bottom: 150, left: 300 };
            const width = 1600 - margin.left - margin.right;
            const height = Math.max(400, (dataPoints.length * 40)) - margin.top - margin.bottom;
            const chart = svg
                .attr("width", width + margin.left + margin.right)
                .attr("height", height + margin.top + margin.bottom)
                .append("g")
                .attr("transform", `translate(${margin.left},${margin.top})`);

            const x = d3.scaleLinear()
                .domain([0, d3.max(dataPoints, d => d.y)])
                .nice()
                .range([0, width]);

            const y = d3.scaleBand()
                .domain(dataPoints.map(d => d.label))
                .range([height, 0])
                .padding(0.3);

            chart.append("g")
                .attr("class", "x-axis")
                .attr("transform", `translate(0,${height})`)
                .call(d3.axisBottom(x)
                    .ticks(d3.max(dataPoints, d => d.y) > 20 ? undefined : d3.max(dataPoints, d => d.y))
                    .tickFormat(d3.format("d"))
                    .tickSize(-height)
                    .tickPadding(10)
                )
                .selectAll("text")
                .style("font-weight", "bold")
                .style("font-size", "14px");

            chart.select(".x-axis .domain").style("display", "none");
            chart.append("g")
                .attr("class", "y-axis")
                .call(d3.axisLeft(y)
                    .tickSize(-width)
                )
                .call(d3.axisLeft(y))
                .selectAll("text")
                .style("font-weight", "bold")
                .style("font-size", "14px");

            chart.selectAll(".bar")
                .data(dataPoints)
                .enter()
                .append("rect")
                .attr("class", "bar")
                .attr("x", 0)
                .attr("y", d => y(d.label))
                .attr("width", d => x(d.y))
                .attr("height", y.bandwidth())
                .attr("fill", "#18479A");

            chart.selectAll(".label")
                .data(dataPoints)
                .enter()
                .append("text")
                .attr("class", "label")
                .attr("x", d => x(d.y) + 5)
                .attr("y", d => y(d.label) + y.bandwidth() / 2)
                .attr("dy", ".35em")
                .attr("text-anchor", "start")
                .style("font-weight", "bold")
                .text(d => d.y);

            chart.append("text")
                .attr("class", "y-axis-label")
                .attr("transform", "rotate(-90)")
                .attr("x", -height / 2)
                .attr("y", -margin.left + 20)
                .attr("text-anchor", "middle")
                .attr("font-size", "23px")
                .attr("font-weight", "bold")
                .text(yAxisLabel);

            chart.append("text")
                .attr("class", "x-axis-label")
                .attr("x", width / 2)
                .attr("y", height + margin.bottom)
                .attr("text-anchor", "middle")
                .attr("font-size", "23px")
                .attr("font-weight", "bold")
                .text(`Number of Demands Created: ${totalNumCases}`);
            } else {
                const allZero = MPoints.every(d => d.value === 0);
                const svg = d3.select(chartRef.current);
                svg.selectAll("*").remove();
                const margin = { top: 20, right: 30, bottom: 50, left: 150 };
                const width = 1850 - margin.left - margin.right;
                const height = 500 - margin.top - margin.bottom;
                const chart = svg
                    .attr("width", width + margin.left + margin.right)
                    .attr("height", height + margin.top + margin.bottom)
                    .append("g")
                    .attr("transform", `translate(${margin.left},${margin.top})`);
            
                // Calculate max value for scaling
                const maxValue = d3.max(MPoints, d => d.value);
            
                const x = d3.scaleBand()
                .domain(MPoints.map(d => d.label))
                    .range([1, width])
                    .padding(0.1);
            
                const y = d3.scaleLinear()
                    .domain([0, maxValue])
                    .nice()
                    .range([height, 0]);

                // Append a single grid line at the bottom of the y-axis
                chart.append("line")
                    .attr("class", "bottom-grid-line")
                    .attr("x1", 0)
                    .attr("y1", height)
                    .attr("x2", width)
                    .attr("y2", height)
                    .attr("stroke", "black")  // Change to a darker color for bold effect
                    .attr("stroke-width",0.7); 

                // Append bars to the chart
                if (!allZero) {
                    const bars = chart.selectAll(".bar")
                        .data(MPoints)
                        .enter()
                        .append("g")
                        .attr("class", "bar")
                        .attr("transform", d => `translate(${x(d.label)}, 0)`);
                
                    bars.append("rect")
                        .attr("y", d => y(d.value))
                        .attr("height", d => height - y(d.value))
                        .attr("width", x.bandwidth())
                        .attr("fill", "#18479A");
                
                    bars.append("text")
                        .attr("class", "label")
                        .attr("x", x.bandwidth() / 2)
                        .attr("y", d => y(d.value) - 5)
                        .attr("text-anchor", "middle")
                        .style("font-weight", "bold")
                        .text(d => d.value);
                }
               

                // Append x-axis to the chart
                chart.append("g")
                    .attr("class", "x-axis")
                    .attr("transform", `translate(0,${height})`)
                    .call(d3.axisBottom(x))
                    .selectAll("text")
                    .style("text-anchor", "middle") 
                    .style("font-weight", "bold")
                    .style("font-size", "14px");

                chart.select(".x-axis .domain").style("display", "none");

                // Append y-axis to the chart
                chart.append("g")
                    .attr("class", "y-axis")
                    .call(d3.axisLeft(y)
                        .ticks(maxValue > 10 ? 10 : maxValue)
                        .tickFormat(d3.format("d"))
                    )
                    .selectAll("text")
                    .style("font-weight", "bold")
                    .style("font-size", "14px");

                // Append x-axis label
                chart.append("text")
                    .attr("class", "x-axis-label")
                    .attr("x", width / 2)
                    .attr("y", height + margin.bottom)
                    .attr("text-anchor", "middle")
                    .attr("font-size", "23px")
                    .attr("font-weight", "bold")
                    .text("Months");

                // Append y-axis label
                chart.append("text")
                    .attr("class", "y-axis-label")
                    .attr("x", -height / 2)
                    .attr("y",  -40)
                    .attr("transform", "rotate(-90)")
                    .attr("text-anchor", "middle")
                    .attr("font-size", "23px")
                    .attr("font-weight", "bold")
                    .text(`Number of Demands`);
            }
    };

    return (
        <>
            <div className="graph-container-one" style={{ paddingTop: '20px',textAlign:"center", paddingBottom: '20px' }}>
                <svg ref={chartRef} width="1000" height="400"></svg>
            </div>
        </>
    );
};

export default Graph;