import React, { useState, useEffect, useRef, useCallback } from "react";
import '../style.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaCalendarAlt } from 'react-icons/fa';
import Constants from "../../../Constants";
import { toaster } from "../../../utils/toast";
import useAxios from "../../../hooks/useAxios";
import XLoader from "../../../components/common/XLoader";
import Graph from "../../admin/dashboard/components/Graph";

const SuperDashboard = () => {
    const currentDate = new Date();
    const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

    const [dataPoints, setDataPoints] = useState([]);
    const [from, setFrom] = useState(startOfMonth);
    const [to, setTo] = useState(currentDate);
    const fromDatePickerRef = useRef(null);
    const toDatePickerRef = useRef(null);
    const [timeTaken, setTimetaken] = useState(0);
    const [totalNumCases, setTotalNumCases] = useState(0);

    const { postData: companyAnalyticsPostData, isLoading: companyAnalyticsIsLoading} = useAxios()

    const getCompanyAnalytics = useCallback(async () => {
        const { success, data, message } = await companyAnalyticsPostData(Constants.ApiUrl.super.dashboard.companyAnalytics, { from, to });

        if (success) {
            console.log(data);
            const {averageTimeTaken, totalCases, topCompanyAnalytic} = data
            setTimetaken(averageTimeTaken)
            setDataPoints(topCompanyAnalytic)
            setTotalNumCases(totalCases)
        } else {
            toaster({ message, success });
        }
    }, [from, to, companyAnalyticsPostData]);

    useEffect(() => {
        if (from && to) {
            getCompanyAnalytics()
        }
    }, [from, to, getCompanyAnalytics])


    const handleToDateChange = (date) => {
        setFrom(date);
        fromDatePickerRef.current.setOpen(false);
    };

    const handleFromDateChange = (date) => {
        setTo(date);
        toDatePickerRef.current.setOpen(false);
    };

    const toDatePickerClickHandler = () => {
        toDatePickerRef.current.setOpen(true);
    };

    const fromDatePickerClickHandler = () => {
        fromDatePickerRef.current.setOpen(true);
    };

    return (
        <div className="row-container">
            <div className="graph-container">
                <div className="date-picker-container">
                    <div className="date-picker-wrapper">
                        <div className="label">Start Date :</div>
                        <DatePicker
                            id="end-date-picker"
                            ref={fromDatePickerRef}
                            selected={from}
                            onChange={handleToDateChange}
                            maxDate={new Date()}
                            dateFormat="MM/dd/yyyy" // customize date format if needed
                            popperPlacement="bottom" // position the calendar below the input field
                            className="custom-datepicker"
                        />
                        <div className="icon-wrapper" onClick={fromDatePickerClickHandler}>
                            <FaCalendarAlt className="calendar-icon" />
                        </div>
                    </div>
                    <div className="date-picker-wrapper">
                        <div className="label">End Date :</div>
                        <DatePicker
                            id="end-date-picker"
                            ref={toDatePickerRef}
                            selected={to}
                            onChange={handleFromDateChange}
                            maxDate={new Date()}
                            dateFormat="MM/dd/yyyy" // customize date format if needed
                            popperPlacement="bottom" // position the calendar below the input field
                            className="custom-datepicker"
                        />
                        <div className="icon-wrapper" onClick={toDatePickerClickHandler}>
                            <FaCalendarAlt className="calendar-icon" />
                        </div>
                    </div>
                </div>
                <div className="graph-container-one" style={{ paddingTop: '20px', paddingBottom:"20px" }}>

                    {companyAnalyticsIsLoading && <XLoader />}
                    
                    {!companyAnalyticsIsLoading && <Graph dataPoints={dataPoints} totalNumCases={totalNumCases} graph={true} yAxisLabel='Companies' />}

                </div>
            </div>

            <div className="page-container">
                <div className="top-content">Average Demand Creation Time:</div>
                <div className="center-content">{timeTaken} Minutes</div>
                <div className="bottom-right-text">{totalNumCases} Demands</div>
            </div>
        </div>
    );
}

export default SuperDashboard;