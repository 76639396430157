

import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { RenderIf } from "../../components";
import XLoader from "../../components/common/XLoader";
import XSelect from "../../components/common/XSelect";
import Constants from "../../Constants";
import '../style.css';
import withTemplateFormContext from "./hoc/withTemplateFormContext";
import TemplateEditableField from './TemplateEditableField';
import { useTemplateFormContext } from "./TemplateFormContext";

const DefaultUIMNonPolicyLimitDemand = () => {
    const { templateData, state, onChangeState, sanitizeHtml, getTemplateIsLoading } = useTemplateFormContext()

    return (
        <>

            {getTemplateIsLoading && <XLoader />}

            {!getTemplateIsLoading &&

                <div>
                    <div className="row">
                        {/* <div className="col-md-12">
                                <div className="file-upload mb-5">
                                    <div className="text text-center">
                                        <input type="file" onChange={onFileSelected} />
                                        <i className="fa-solid fa-upload"></i>
                                        <p>Upload file type, .jpg, .png</p>
                                    </div>
                                </div>
                            </div> */}
                    </div>

                    <div className="row">
                        <div className="col-md-6">

                            <div className="col-md-12">
                                <TemplateEditableField
                                    fieldName="uimnpattorneyName"
                                    label="Attorney Name"
                                    placeholder="Enter Attorney Name"
                                />

                            </div>

                            <div className="col-md-12 mt-5">
                                <TemplateEditableField
                                    fieldName="uimnpattorneyEmail"
                                    label="Attorney Email"
                                    placeholder="Enter Attorney Email"
                                />

                            </div>
                            <div className="col-md-12 mt-5">
                                <div>
                                    <XSelect
                                        emptyOption={false}
                                        label="State"
                                        value={state}
                                        onChange={onChangeState}
                                        options={[{ value: "California", text: "California" }, ...Constants.Dropdowns.States]}
                                    />
                                </div>
                            </div>

                            <div className="col-md-12 mt-5">
                                <TemplateEditableField
                                    fieldName="uimnpfirmAddress"
                                    label="Firm Address"
                                    placeholder="Enter Firm Address"
                                />

                            </div>


                            <div className="col-md-12 mt-5">
                                <TemplateEditableField
                                    fieldName="uimnpintroductionTitle"
                                    label="Introduction Title"
                                    placeholder="Enter Introduction title"
                                    isReferenceVariableAvailable={true}
                                />

                            </div>

                            <div className="col-md-12 mt-5">
                                <TemplateEditableField
                                    fieldName="uimnpintroductionDescription"
                                    label="Introduction Description"
                                    placeholder="Enter Introduction Description"
                                    isReferenceVariableAvailable={true}
                                />

                            </div>

                            <div className="col-md-12 mt-5">
                                <TemplateEditableField
                                    fieldName="uimnpliabilityTitle"
                                    label="Liability Title"
                                    placeholder="Enter liability title"
                                    isReferenceVariableAvailable={true}
                                />

                            </div>

                            <div className="col-md-12 mt-5">
                                <TemplateEditableField
                                    fieldName="uimnpliabilityDescription"
                                    label="Liability Description"
                                    placeholder="Enter liability Description"
                                    isReferenceVariableAvailable={true}
                                />

                            </div>

                            <div className="col-md-12 mt-5">
                                <TemplateEditableField
                                    fieldName="uimnppriorMedicalRecordTitle"
                                    label="Prior Medical Record Title"
                                    placeholder="Enter Prior Medical Record title"
                                    isReferenceVariableAvailable={true}
                                />

                            </div>

                            <div className="col-md-12 mt-5">
                                <TemplateEditableField
                                    fieldName="uimnppriorMedicalRecordDescription"
                                    label="Prior Medical Record Description"
                                    placeholder="Enter Prior Medical Record description"
                                    isReferenceVariableAvailable={true}
                                />

                            </div>

                            <div className="col-md-12 mt-5">
                                <TemplateEditableField
                                    fieldName="uimnpnonEconomicDamageTitle"
                                    label="Non-Economic Damages Title"
                                    placeholder="Enter Non-Economic Damages title"
                                    isReferenceVariableAvailable={true}
                                />

                            </div>

                            <div className="col-md-12 mt-5">
                                <TemplateEditableField
                                    fieldName="uimnpnonEconomicDamageDescription"
                                    label="Non-Economic Damage Description"
                                    placeholder="Enter Non-Economic damages description"
                                    isReferenceVariableAvailable={true}
                                />

                            </div>
                            <div className="col-md-12 mt-5">
                                <TemplateEditableField
                                    fieldName="uimnplossOfIncomeTitle"
                                    label="Loss of Income Title"
                                    placeholder="Enter loss of Income"
                                    isReferenceVariableAvailable={true}
                                />

                            </div>

                            <div className="col-md-12 mt-5">
                                <TemplateEditableField
                                    fieldName="uimnplossOfIncomeDescription"
                                    label="Loss of Income Description"
                                    placeholder="Enter Loss of Income description"
                                    isReferenceVariableAvailable={true}
                                />

                            </div>
                            <div className="col-md-12 mt-5">
                                <TemplateEditableField
                                    fieldName="uimnpsettlementDemandTitle"
                                    label="Settlement Demand Title"
                                    placeholder="Enter Settlement demand title"
                                    isReferenceVariableAvailable={true}
                                />

                            </div>
                            <div className="col-md-12 mt-5">
                                <TemplateEditableField
                                    fieldName="uimnpsettlementDemandDescription"
                                    label="Settlement Demand Description"
                                    placeholder="Enter settlement demand description"
                                    isReferenceVariableAvailable={true}
                                />

                            </div>

                            <div className="col-md-12 mt-5">
                                <TemplateEditableField
                                    fieldName="uimnptermOfSettlementTitle"
                                    label="Term of Settlement Title"
                                    placeholder="Enter term of settlement title"
                                    isReferenceVariableAvailable={true}
                                />

                            </div>
                            <div className="col-md-12 mt-5">
                                <TemplateEditableField
                                    fieldName="uimnptermOfSettlementDescription"
                                    label="Term of Settlement Description"
                                    placeholder="Enter term of settlement description"
                                    isReferenceVariableAvailable={true}
                                />

                            </div>
                            <div className="col-md-12 mt-5">
                                <TemplateEditableField
                                    fieldName="uimnpFirmName"
                                    label="Firm Name"
                                    placeholder="Enter firm Name"
                                />

                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="mb-4">
                                <h2>Preview</h2>
                                <div className="editing-Field" >
                                    <div className="row">
                                        <div className="col-md-3">
                                            <ReactQuill
                                                value={sanitizeHtml(templateData?.uimnpattorneyName)}
                                                modules={{ toolbar: false }}
                                                readOnly={true}
                                                theme="bubble"
                                            />
                                        </div>
                                        <div className="col-md-5">
                                            <RenderIf shouldRender={templateData?.companyLogo}>
                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <img alt="companyLogo" src={templateData?.companyLogo} width="300px" height="200px" style={{ display: 'center' }} />
                                                </div>
                                            </RenderIf>
                                        </div>
                                        <div className="col-md-4">
                                            <ReactQuill
                                                value={sanitizeHtml(templateData?.uimnpattorneyEmail)}
                                                modules={{ toolbar: false }}
                                                readOnly={true}
                                                theme="bubble"
                                            />
                                        </div>
                                    </div>
                                    <ReactQuill
                                        value={sanitizeHtml(templateData?.uimnpfirmAddress)}
                                        modules={{ toolbar: false }}
                                        readOnly={true}
                                        theme="bubble"
                                    />
                                    <ReactQuill
                                        value={sanitizeHtml(templateData?.uimnpintroductionTitle)}
                                        modules={{ toolbar: false }}
                                        readOnly={true}
                                        theme="bubble"
                                    />
                                    <ReactQuill
                                        value={sanitizeHtml(templateData?.uimnpintroductionDescription)}
                                        modules={{ toolbar: false }}
                                        readOnly={true}
                                        theme="bubble"
                                    />
                                    <ReactQuill
                                        value={sanitizeHtml(templateData?.uimnpliabilityTitle)}
                                        modules={{ toolbar: false }}
                                        readOnly={true}
                                        theme="bubble"
                                    />
                                    <ReactQuill
                                        value={sanitizeHtml(templateData?.uimnpliabilityDescription)}
                                        modules={{ toolbar: false }}
                                        readOnly={true}
                                        theme="bubble"
                                    />

                                    <ReactQuill
                                        value={sanitizeHtml(templateData?.uimnppriorMedicalRecordTitle)}
                                        modules={{ toolbar: false }}
                                        readOnly={true}
                                        theme="bubble"
                                    />

                                    <ReactQuill
                                        value={sanitizeHtml(templateData?.uimnppriorMedicalRecordDescription)}
                                        modules={{ toolbar: false }}
                                        readOnly={true}
                                        theme="bubble"
                                    />

                                    <ReactQuill
                                        value={sanitizeHtml(templateData?.uimnpnonEconomicDamageTitle)}
                                        modules={{ toolbar: false }}
                                        readOnly={true}
                                        theme="bubble"
                                    />

                                    <ReactQuill
                                        value={sanitizeHtml(templateData?.uimnpnonEconomicDamageDescription)}
                                        modules={{ toolbar: false }}
                                        readOnly={true}
                                        theme="bubble"
                                    />

                                    <ReactQuill
                                        value={sanitizeHtml(templateData?.uimnpsettlementDemandTitle)}
                                        modules={{ toolbar: false }}
                                        readOnly={true}
                                        theme="bubble"
                                    />
                                    <ReactQuill
                                        value={sanitizeHtml(templateData?.uimnpsettlementDemandDescription)}
                                        modules={{ toolbar: false }}
                                        readOnly={true}
                                        theme="bubble"
                                    />

                                    <ReactQuill
                                        value={sanitizeHtml(templateData?.uimnplossOfIncomeTitle)}
                                        modules={{ toolbar: false }}
                                        readOnly={true}
                                        theme="bubble"
                                    />

                                    <ReactQuill
                                        value={sanitizeHtml(templateData?.uimnplossOfIncomeDescription)}
                                        modules={{ toolbar: false }}
                                        readOnly={true}
                                        theme="bubble"
                                    />
                                    <ReactQuill
                                        value={sanitizeHtml(templateData?.uimnptermOfSettlementTitle)}
                                        modules={{ toolbar: false }}
                                        readOnly={true}
                                        theme="bubble"
                                    />
                                    <ReactQuill
                                        value={sanitizeHtml(templateData?.uimnptermOfSettlementDescription)}
                                        modules={{ toolbar: false }}
                                        readOnly={true}
                                        theme="bubble"
                                    />
                                    <ReactQuill
                                        value={sanitizeHtml(templateData?.uimnpFirmName)}
                                        modules={{ toolbar: false }}
                                        readOnly={true}
                                        theme="bubble"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )

}

export default withTemplateFormContext(DefaultUIMNonPolicyLimitDemand, 'UIMNonPolicyLimitDemand')