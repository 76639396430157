export const getAuthtoken = () => {
    const authToken = getLocalStorageItem("authtoken")
    return authToken
};

export const getLocalStorageItem = (key) => {
    const val = localStorage.getItem(key)
    return val
};

export const removeSpecialCharacters = (str, charsToRemove = `!"#$%&'()*+,-./:;<=>?@[\\]^_\`{|}~`) => {
    const escapedChars = charsToRemove.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    const regex = new RegExp(`[${escapedChars}]`, 'g');
    return str.replace(regex, '');
}

export const separateFilenameAndExtension = (filename) => {
    const lastDotIndex = filename.lastIndexOf('.');
    if (lastDotIndex === -1) {
        return { name: filename, ext: '' };
    }
    const name = filename.slice(0, lastDotIndex);
    const ext = filename.slice(lastDotIndex + 1);
    return { name, ext };
}

export function debounce(func, delay) {
    let timeoutId;

    return function (...args) {
        clearTimeout(timeoutId);

        timeoutId = setTimeout(() => {
            func.apply(this, args);
        }, delay);
    };
}
