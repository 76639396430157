import Editor from './Editor';
import { useTemplateFormContext } from './TemplateFormContext';

const TemplateEditableField = ({ fieldName, label, placeholder, isReferenceVariableAvailable }) => {

    const { templateData, fieldLoader } = useTemplateFormContext();
    const isLoading = !!fieldLoader?.[fieldName]

    return (
        <div className={isLoading && 'disabled'}>
            <Editor
                name={fieldName}
                placeholder={placeholder}
                label={label}
                isReferenceVariableAvailable={isReferenceVariableAvailable}
                value={templateData[fieldName]}
            />
        </div>
    );
};

export default TemplateEditableField;
