import React from 'react'
import { Pagination } from '@mui/material';

const ReactPagination = ({ currentPage  , totalItemCount , onChange }) => {
  return (
    <Pagination
          page={currentPage}
          count={totalItemCount}
          onChange={onChange}
          color="secondary" 
          shape="rounded"
        />
  )
}

export default ReactPagination