import axios from 'axios';
import AppConfig from '../AppConfig';
import Constants from '../Constants';
import { headers } from '.';
class UserService {
    static AddUser = (payloadData) => {
        const url = `${AppConfig.ApiBaseUrl}${Constants.ApiUrl.user.add}`;
        return axios.post(url, payloadData,{...headers()})
    }

    static GetUsers = (pageNumber , limit , searchText) => {
        const url = `${AppConfig.ApiBaseUrl}${Constants.ApiUrl.user.getUsers}?pageNumber=${pageNumber}&limit=${limit}&searchText=${searchText}`;
        return axios.get(url,{...headers()}).then(resp => resp.data);
    }
    static filterUser = (payloadData) => {
        const url = `${AppConfig.ApiBaseUrl}${Constants.ApiUrl.user.getfilterUser}`;
        return axios.post(url, payloadData,{...headers()})
    }
    static filterMonth = (payloadData) => {
        const url = `${AppConfig.ApiBaseUrl}${Constants.ApiUrl.user.getfilterMonth}`;
        
        return axios.post(url, payloadData,{...headers()})
    }
    static UpdateProfile = (payloadData) => {
        const url = `${AppConfig.ApiBaseUrl}${Constants.ApiUrl.user.updateProfile}`;
        return axios.put(url, payloadData,{...headers()})
    }
    static UpdateUser = (payloadData) => {
        const url = `${AppConfig.ApiBaseUrl}${Constants.ApiUrl.user.updateUser}`;
        return axios.post(url, payloadData,{...headers()})
    }
}

export default UserService;