import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { RenderIf } from "../../components";
import XLoader from "../../components/common/XLoader";
import XSelect from "../../components/common/XSelect";
import Constants from "../../Constants";
import '../style.css';
import withTemplateFormContext from "./hoc/withTemplateFormContext";
import TemplateEditableField from './TemplateEditableField';
import { useTemplateFormContext } from "./TemplateFormContext";

const DefaultThirdPartyNonPolicyLimitDemand = () => {
    const { templateData, state, onChangeState, sanitizeHtml, getTemplateIsLoading } = useTemplateFormContext()


    return (
        <>
            {getTemplateIsLoading && <XLoader />}

            {!getTemplateIsLoading &&

                <div>
                    <div className="row">
                        {/* <div className="col-md-12">
                                <div className="file-upload mb-5">
                                    <div className="text text-center">
                                        <input type="file" onChange={onFileSelected} />
                                        <i className="fa-solid fa-upload"></i>
                                        <p>Upload file type, .jpg, .png</p>
                                    </div>
                                </div>
                            </div> */}
                    </div>
                    <div className="row">
                        <div className="col-md-6">

                            <div className="col-md-12">
                                <TemplateEditableField
                                    fieldName="tpnpattorneyName"
                                    label="Attorney Name"
                                    placeholder="Enter Attorney Name"
                                />

                            </div>

                            <div className="col-md-12 mt-5">
                                <TemplateEditableField
                                    fieldName="tpnpattorneyEmail"
                                    label="Attorney Email"
                                    placeholder="Enter Attorney Email"
                                />
                            </div>
                            <div className="col-md-12 mt-5">
                                <div>
                                    <XSelect
                                        emptyOption={false}
                                        label="State"
                                        value={state}
                                        onChange={onChangeState}
                                        options={[{ value: "California", text: "California" }, ...Constants.Dropdowns.States]}
                                    />
                                </div>
                            </div>

                            <div className="col-md-12 mt-5">
                                <TemplateEditableField
                                    fieldName="tpnpfirmAddress"
                                    label="Firm Address"
                                    placeholder="Enter Firm Address"
                                />

                            </div>


                            <div className="col-md-12 mt-5">
                                <TemplateEditableField
                                    fieldName="tpnpintroductionTitle"
                                    label="Introduction Title"
                                    placeholder="Enter Introduction title"
                                    isReferenceVariableAvailable={true}
                                />

                            </div>

                            <div className="col-md-12 mt-5">
                                <TemplateEditableField
                                    fieldName="tpnpintroductionDescription"
                                    label="Introduction Description"
                                    placeholder="Enter Introduction Description"
                                    isReferenceVariableAvailable={true}
                                />

                            </div>

                            <div className="col-md-12 mt-5">
                                <TemplateEditableField
                                    fieldName="tpnpliabilityTitle"
                                    label="Liability Title"
                                    placeholder="Enter liability title"
                                    isReferenceVariableAvailable={true}
                                />

                            </div>

                            <div className="col-md-12 mt-5">
                                <TemplateEditableField
                                    fieldName="tpnpliabilityDescription"
                                    label="Liability Description"
                                    placeholder="Enter liability Description"
                                    isReferenceVariableAvailable={true}
                                />

                            </div>

                            <div className="col-md-12 mt-5">
                                <TemplateEditableField
                                    fieldName="tpnppriorMedicalRecordTitle"
                                    label="Prior Medical Record Title"
                                    placeholder="Enter Prior Medical Record title"
                                    isReferenceVariableAvailable={true}
                                />

                            </div>

                            <div className="col-md-12 mt-5">
                                <TemplateEditableField
                                    fieldName="tpnppriorMedicalRecordDescription"
                                    label="Prior Medical Record Description"
                                    placeholder="Enter Prior Medical Record description"
                                    isReferenceVariableAvailable={true}
                                />

                            </div>

                            <div className="col-md-12 mt-5">
                                <TemplateEditableField
                                    fieldName="tpnpnonEconomicDamageTitle"
                                    label="Non-Economic Damages Title"
                                    placeholder="Enter Non-Economic Damages title"
                                    isReferenceVariableAvailable={true}
                                />
                            </div>


                            <div className="col-md-12 mt-5">
                                <TemplateEditableField
                                    fieldName="tpnpnonEconomicDamageDescription"
                                    label="Non-Economic Damage Description"
                                    placeholder="Enter Non-Economic damages description"
                                    isReferenceVariableAvailable={true}
                                />
                            </div>
                            <div className="col-md-12 mt-5">
                                <TemplateEditableField
                                    fieldName="tpnplossOfIncomeTitle"
                                    label="Loss of Income Title"
                                    placeholder="Enter loss of Income"
                                    isReferenceVariableAvailable={true}
                                />
                            </div>


                            <div className="col-md-12 mt-5">
                                <TemplateEditableField
                                    fieldName="tpnplossOfIncomeDescription"
                                    label="Loss of Income Description"
                                    placeholder="Enter Loss of Income description"
                                    isReferenceVariableAvailable={true}
                                />
                            </div>

                            <div className="col-md-12 mt-5">
                                <TemplateEditableField
                                    fieldName="tpnpsettlementDemandTitle"
                                    label="Settlement Demand Title"
                                    placeholder="Enter Settlement demand title"
                                    isReferenceVariableAvailable={true}
                                />
                            </div>

                            <div className="col-md-12 mt-5">
                                <TemplateEditableField
                                    fieldName="tpnpsettlementDemandDescription"
                                    label="Settlement Demand Description"
                                    placeholder="Enter settlement demand description"
                                    isReferenceVariableAvailable={true}
                                />
                            </div>

                            <div className="col-md-12 mt-5">
                                <TemplateEditableField
                                    fieldName="tpnptermOfSettlementTitle"
                                    label="Term of Settlement Title"
                                    placeholder="Enter term of settlement title"
                                    isReferenceVariableAvailable={true}
                                />
                            </div>

                            <div className="col-md-12 mt-5">
                                <TemplateEditableField
                                    fieldName="tpnptermOfSettlementDescription"
                                    label="Term of Settlement Description"
                                    placeholder="Enter term of settlement description"
                                    isReferenceVariableAvailable={true}
                                />
                            </div>

                            <div className="col-md-12 mt-5">
                                <TemplateEditableField
                                    fieldName="tpnpFirmName"
                                    label="Firm Name"
                                    placeholder="Enter Firm Name"
                                />
                            </div>

                        </div>
                        <div className="col-md-6">
                            <div className="mb-4">
                                <h2>Preview</h2>
                                <div className="editing-Field" >
                                    <div className="row">
                                        <div className="col-md-3">
                                            <ReactQuill
                                                value={sanitizeHtml(templateData?.tpnpattorneyName)}
                                                modules={{ toolbar: false }}
                                                readOnly={true}
                                                theme="bubble"
                                            />
                                        </div>
                                        <div className="col-md-5">
                                            <RenderIf shouldRender={templateData?.companyLogo}>
                                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <img alt="companyLogo" src={templateData?.companyLogo} width="300px" height="200px" style={{ display: 'center' }} />
                                                </div>
                                            </RenderIf>
                                        </div>
                                        <div className="col-md-4">
                                            <ReactQuill
                                                value={sanitizeHtml(templateData?.tpnpattorneyEmail)}
                                                modules={{ toolbar: false }}
                                                readOnly={true}
                                                theme="bubble"
                                            />
                                        </div>
                                    </div>
                                    <ReactQuill
                                        value={sanitizeHtml(templateData?.tpnpfirmAddress)}
                                        modules={{ toolbar: false }}
                                        readOnly={true}
                                        theme="bubble"
                                    />
                                    <ReactQuill
                                        value={sanitizeHtml(templateData?.tpnpintroductionTitle)}
                                        modules={{ toolbar: false }}
                                        readOnly={true}
                                        theme="bubble"
                                    />
                                    <ReactQuill
                                        value={sanitizeHtml(templateData?.tpnpintroductionDescription)}
                                        modules={{ toolbar: false }}
                                        readOnly={true}
                                        theme="bubble"
                                    />
                                    <ReactQuill
                                        value={sanitizeHtml(templateData?.tpnpliabilityTitle)}
                                        modules={{ toolbar: false }}
                                        readOnly={true}
                                        theme="bubble"
                                    />
                                    <ReactQuill
                                        value={sanitizeHtml(templateData?.tpnpliabilityDescription)}
                                        modules={{ toolbar: false }}
                                        readOnly={true}
                                        theme="bubble"
                                    />

                                    <ReactQuill
                                        value={sanitizeHtml(templateData?.tpnppriorMedicalRecordTitle)}
                                        modules={{ toolbar: false }}
                                        readOnly={true}
                                        theme="bubble"
                                    />

                                    <ReactQuill
                                        value={sanitizeHtml(templateData?.tpnppriorMedicalRecordDescription)}
                                        modules={{ toolbar: false }}
                                        readOnly={true}
                                        theme="bubble"
                                    />

                                    <ReactQuill
                                        value={sanitizeHtml(templateData?.tpnpnonEconomicDamageTitle)}
                                        modules={{ toolbar: false }}
                                        readOnly={true}
                                        theme="bubble"
                                    />

                                    <ReactQuill
                                        value={sanitizeHtml(templateData?.tpnpnonEconomicDamageDescription)}
                                        modules={{ toolbar: false }}
                                        readOnly={true}
                                        theme="bubble"
                                    />
                                    <ReactQuill
                                        value={sanitizeHtml(templateData?.tpnplossOfIncomeTitle)}
                                        modules={{ toolbar: false }}
                                        readOnly={true}
                                        theme="bubble"
                                    />

                                    <ReactQuill
                                        value={sanitizeHtml(templateData?.tpnplossOfIncomeDescription)}
                                        modules={{ toolbar: false }}
                                        readOnly={true}
                                        theme="bubble"
                                    />
                                    <ReactQuill
                                        value={sanitizeHtml(templateData?.tpnpsettlementDemandTitle)}
                                        modules={{ toolbar: false }}
                                        readOnly={true}
                                        theme="bubble"
                                    />
                                    <ReactQuill
                                        value={sanitizeHtml(templateData?.tpnpsettlementDemandDescription)}
                                        modules={{ toolbar: false }}
                                        readOnly={true}
                                        theme="bubble"
                                    />
                                    <ReactQuill
                                        value={sanitizeHtml(templateData?.tpnptermOfSettlementTitle)}
                                        modules={{ toolbar: false }}
                                        readOnly={true}
                                        theme="bubble"
                                    />
                                    <ReactQuill
                                        value={sanitizeHtml(templateData?.tpnptermOfSettlementDescription)}
                                        modules={{ toolbar: false }}
                                        readOnly={true}
                                        theme="bubble"
                                    />
                                    <ReactQuill
                                        value={sanitizeHtml(templateData?.tpnpFirmName)}
                                        modules={{ toolbar: false }}
                                        readOnly={true}
                                        theme="bubble"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default withTemplateFormContext(DefaultThirdPartyNonPolicyLimitDemand, 'ThirdPartyNonEconomicPoliceLimitDemand')