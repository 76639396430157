import React, { useEffect, useRef, useState } from 'react';
import { Field, Formik, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { TextInput, Dropdown, RenderIf, } from '../../../../components';
import Constants from '../../../../Constants';
import { GenerateUUID } from '../../../../Utils';
import { useSelector, useDispatch } from 'react-redux';
import { storeInjuryAnalysisEstern } from '../../../../redux/actions/liabilty';


const InjuriesAnalysisForm = ({ onSubmit, stepDecrement }) => {
    const dispatch = useDispatch();
    const InjuryData = useSelector(state => state.injuryData.injuryData);
    const [medicalBillName, setMedicalBillName] = useState([]);
    const [medicalRecordsFile, setMedicalRecordsFile] = useState([])
    const [premedicalRecordsFile, setPreMedicalRecordsFile] = useState([])
    const [pdfFile, setPdfFile] = useState([])
    const [values, setValues] = useState({})
    const [multipleMedicalProviderandFacitlityName, setMultipleMedicalProviderandFacitlityName] = useState([])
    const [premultipleMedicalProviderandFacitlityName, setPreMultipleMedicalProviderandFacitlityName] = useState([])
    const [medicalBills, setMedicalBills] = useState([]);
    const [medicalRecordFileName, setMedicalRecordFileName] = useState([])
    const [premedicalRecordFileName, setPreMedicalRecordFileName] = useState([])
    let [medicalProviderCounter, setMedicalProviderCounter] = useState([])
    let [premedicalProviderCounter, setPreMedicalProviderCounter] = useState([])
    const [error, setErrors] = useState({});
    const medicalProviderName = useRef(null);
    const [amount, setAmount] = useState('')
    const [gender, setGender] = useState('')
    const [age, setAge] = useState('')
    const [fileArray, SetFilearray] = useState([])
    const [isSubmitting, setIsSubmitting] = useState(false);


    const newMedicalRecordFileName = [...medicalRecordFileName];

    useEffect(() => {
        console.log(InjuryData, "in the injury data");
        console.log(medicalRecordsFile)
        console.log(medicalBillName)
        console.log(medicalBills)
        console.log(fileArray);
        if (InjuryData && Object.keys(InjuryData).length > 0) {

            const transformedMedicalProviderBillsName = InjuryData?.medicalBillsData.map(fileArray => {
                if (Array.isArray(fileArray)) {
                    return fileArray.map(file => file.name);
                }
            });
            const transformedMedicalRecordsFileName = InjuryData?.medicalRecordsFileRecord.map(fileArray => {
                if (Array.isArray(fileArray)) {
                    return fileArray.map(file => file.name);
                }
            });
            const transformedPreMedicalRecordsFileName = InjuryData?.premedicalRecordsFileRecord.map(fileArray => {
                if (Array.isArray(fileArray)) {
                    return fileArray.map(file => file.name);
                }
            });
            const tranformedPreMedicalRecordsFile = InjuryData?.premedicalRecordsFileRecord.map((fileArray, index) => {
                if (Array.isArray(fileArray)) {
                    return fileArray.filter(Boolean);
                }
                return fileArray;
            });
            const transformedMedicalRecordsFile = InjuryData?.medicalRecordsFileRecord.map((fileArray, index) => {
                if (Array.isArray(fileArray)) {
                    return fileArray.filter(Boolean);
                }
                return fileArray;
            });

            const transformedMedicalProviderBills = InjuryData?.medicalBillsData;

            console.log(transformedMedicalRecordsFile, transformedMedicalRecordsFileName, transformedMedicalProviderBills, transformedMedicalProviderBillsName, "transformedMedicalRecordsFile");
            // setAge(InjuryData?.age)
            // setGender(InjuryData?.gender)
            // setAmount(InjuryData?.amount)
            setMedicalBillName(transformedMedicalProviderBillsName)
            setMedicalBills(transformedMedicalProviderBills)
            setMedicalRecordFileName(transformedMedicalRecordsFileName)
            setMedicalRecordsFile(transformedMedicalRecordsFile);
            setPreMedicalRecordFileName(transformedPreMedicalRecordsFileName)
            setPreMedicalRecordsFile(tranformedPreMedicalRecordsFile)
            for (let i = 0; i <= 30; i++) {
                initialValues[`accidentScenes${i}`] = '';
                initialValues[`medicalBills${i}`] = '';
                initialValues[`medicalType${i}`] = InjuryData?.[`medicalType${i}`] || '';
                initialValues[`premedicalType${i}`] = InjuryData?.[`premedicalType${i}`] || '';
                initialValues[`medicalProviderandFacility${i}`] = InjuryData?.[`medicalProviderandFacility${i}`] || '';
                initialValues[`premedicalProviderandFacility${i}`] = InjuryData?.[`premedicalProviderandFacility${i}`] || '';
            }
        }
    }, [InjuryData])


    const validationSchemaforMedicalProviderName = Yup.object().shape({
        medicalProviderName: Yup.string().required('Medical Provider Name is required'),
    })

    const handleInput = (e, innerIndex) => {

        e.preventDefault();
        const name = e.target.value;
        const parts = e.target.name.split('');
        const lastValue = parts[parts.length - 1];
        const indexValue = parseInt(lastValue, 10);
        setMultipleMedicalProviderandFacitlityName((prevNames) => {
            const updatedNames = [...prevNames];
            updatedNames[indexValue] = name;
            console.log(updatedNames)
            return updatedNames;
        });
    }

    const handleInputPreMeicalProviderandFacitlityName = (e, innerIndex) => {

        e.preventDefault();
        const name = e.target.value;
        const parts = e.target.name.split('');
        const lastValue = parts[parts.length - 1];
        const indexValue = parseInt(lastValue, 10);
        setPreMultipleMedicalProviderandFacitlityName((prevNames) => {
            const updatedNames = [...prevNames];
            updatedNames[indexValue] = name;
            console.log(updatedNames)
            return updatedNames;
        });
    }

    const medicalProvidersObject = (medicalRecordsFile, multipleMedicalProviderandFacitlityName, medicalBills, values) => {

        const medicalFiles = medicalRecordsFile//.filter((item, index) => index !== 6);
        const medicalBill = medicalBills//.filter((item, index) => index !== 6);

        let medicalProviderandFacilityValues = [];
        multipleMedicalProviderandFacitlityName = multipleMedicalProviderandFacitlityName.filter(item => item !== undefined)
        for (let i = 0; i <= 30; i++) {
            const key = `medicalProviderandFacility${i}`;
            if (key in values) {
                medicalProviderandFacilityValues.push(values[key]);
            }
        }
        let medicalType = [];
        for (let i = 0; i <= 30; i++) {
            const key = `medicalType${i}`;
            if (key in values) {
                medicalType.push(values[key]);
            }
        }

        if (multipleMedicalProviderandFacitlityName) {
            medicalProviderandFacilityValues = [...medicalProviderandFacilityValues, ...multipleMedicalProviderandFacitlityName]
        }
        const medicalProviderandFacilityValue = medicalProviderandFacilityValues.filter(item => item !== undefined && item.length !== 0 && item !== "");
        const medicalTyp = medicalType.filter(item => item !== undefined && item.length !== 0 && item !== "");

        let updatedErrors
        let pairedArray
        if (medicalFiles.length) {
            pairedArray = medicalProviderandFacilityValue?.map((file, index) => {

                console.log(medicalType, medicalBills, medicalProviderandFacilityValue, "llll");
                const medicalProviderNames = medicalProviderandFacilityValue[index] || [];
                const medicalTypes = medicalTyp[index] ? medicalTyp[index] : 'All Other Medical Records'
                const medicalRecords = medicalFiles ? medicalFiles[index] : []
                const medicalProviderBills = medicalBill ? medicalBill[index] : [];
                const isMedicalRecordAdded = true

                return {
                    fileArray: medicalRecords,
                    medicalProviderNames,
                    isMedicalRecordAdded,
                    medicalProviderBills,
                    medicalTypes
                };
            });
        } else {
            pairedArray = medicalBills?.map((file, index) => {

                const medicalProviderNames = medicalProviderandFacilityValues[index] || '';
                const medicalProviderBills = [];
                const isMedicalRecordAdded = false

                return {
                    fileArray: file,
                    medicalProviderNames,
                    isMedicalRecordAdded,
                    medicalProviderBills
                };
            });
        }

        // 
        console.log(pairedArray)
        return pairedArray;
    }

    const preMedicalProvidersObject = (premedicalRecordsFile, premultipleMedicalProviderandFacitlityName, values) => {
        const preMedicalFiles = premedicalRecordsFile.filter(item => item !== undefined && item.length !== 0);
        let medicalProviderandFacilityValues = [];
        premultipleMedicalProviderandFacitlityName = premultipleMedicalProviderandFacitlityName.filter(item => item !== undefined)
        for (let i = 0; i <= 6; i++) {
            const key = `premedicalProviderandFacility${i}`;
            if (key in values) {
                medicalProviderandFacilityValues.push(values[key]);
            }
        }
        let preMedicalType = [];
        for (let i = 0; i <= 30; i++) {
            const key = `premedicalType${i}`;
            if (key in values) {
                preMedicalType.push(values[key]);
            }
        }

        if (premultipleMedicalProviderandFacitlityName) {
            medicalProviderandFacilityValues = [...medicalProviderandFacilityValues, ...premultipleMedicalProviderandFacitlityName]
        }

        let updatedErrors
        const pairedArray = preMedicalFiles.map((file, index) => {

            const premedicalProviderNames = medicalProviderandFacilityValues[index] || '';
            const medicalTypes = preMedicalType[index] ? preMedicalType[index] : 'All Other Medical Records'

            return {
                fileArray: file,
                premedicalProviderNames,
                medicalTypes
            };
        });
        // 
        console.log(pairedArray)
        return pairedArray;
    }

    const onMedicalBillFileSelect = (files) => {

        files.preventDefault()
        const selectedFiles = files.target.files
        const parts = files.target.id.slice(13);
        const lastValue = parts //=== "7" ? parts - 1 + "" : parts //parts
        const indexValue = parseInt(lastValue, 10);
        const newMedicalBillFile = [...medicalBills];
        const newMedicalBillFileName = [...medicalBillName];

        for (let i = 0; i < selectedFiles.length; i++) {
            const fileIndex = indexValue + i;
            newMedicalBillFile[fileIndex] = selectedFiles[i];
            newMedicalBillFileName[fileIndex] = selectedFiles[i].name;
        }

        setMedicalBills((prevArray) => {
            const newArray = [...prevArray];
            newArray[indexValue] = [
                ...(newArray[indexValue] || []),
                ...newMedicalBillFile.filter(item => item !== undefined)
            ];

            const isNestedArray = (element) => Array.isArray(element);
            let flattenedData = newArray?.map(sublist => sublist?.filter(item => !isNestedArray(item)));
            return flattenedData;
        });

        setMedicalBillName((prevArray) => {
            const newArray = [...prevArray];
            newArray[indexValue] = [
                ...(newArray[indexValue] || []),
                ...newMedicalBillFileName.filter(item => item !== undefined)
            ];

            const isNestedArray = (element) => Array.isArray(element);
            let flattenedData = newArray?.map(sublist => sublist?.filter(item => !isNestedArray(item)));

            return flattenedData;
        });

        console.log(medicalBillName);
        console.log(medicalBills);

    }

    const onMedicalRecordsFile = async (files) => {

        files.preventDefault()
        console.log(medicalProviderName);
        console.log(files);
        const selectedFiles = files.target.files
        const parts = files.target.id.slice(9);
        const lastValue = parts //.length > 5 ? parts - 1 + "" : parts + ""
        const indexValue = parseInt(lastValue, 10);
        const newMedicalRecordsFile = [...medicalRecordsFile];
        const newMedicalRecordFileName = [...medicalRecordFileName];

        for (let i = 0; i < selectedFiles.length; i++) {
            const fileIndex = indexValue + i;
            newMedicalRecordsFile[fileIndex] = selectedFiles[i];
            newMedicalRecordFileName[fileIndex] = selectedFiles[i].name;

        }
        setMedicalRecordsFile((prevArray) => {
            const newArray = [...prevArray];

            newArray[indexValue] = [
                ...(newArray[indexValue] || []),
                ...newMedicalRecordsFile.filter(item => item !== undefined)
            ]
            const isNestedArray = (element) => Array.isArray(element);
            let flattenedData = newArray?.map(sublist => sublist?.filter(item => !isNestedArray(item)));

            return flattenedData;
        });

        setMedicalRecordFileName((prevArray) => {
            const newArray = [...prevArray];

            newArray[indexValue] = [
                ...(newArray[indexValue] || []),
                ...newMedicalRecordFileName.filter(item => item !== undefined)
            ]
            const isNestedArray = (element) => Array.isArray(element);
            let flattenedData = newArray?.map(sublist => sublist?.filter(item => !isNestedArray(item)));
            return flattenedData;
        });
    }

    const onPreMedicalRecordsFile = async (files) => {

        files.preventDefault()
        console.log(medicalProviderName);
        console.log(files);
        const selectedFiles = files.target.files
        const parts = files.target.id.slice(12);
        const lastValue = parts
        const indexValue = parseInt(lastValue, 10);

        const newMedicalRecordsFile = [...medicalRecordsFile];
        const newMedicalRecordFileName = [...medicalRecordFileName];

        for (let i = 0; i < selectedFiles.length; i++) {
            newMedicalRecordsFile[indexValue + i] = selectedFiles[i];
            newMedicalRecordFileName[indexValue + i] = selectedFiles[i].name;

        }

        setPreMedicalRecordsFile((prevArray) => {
            const newArray = [...prevArray];

            newArray[indexValue] = [
                ...(newArray[indexValue] || []),
                ...newMedicalRecordsFile.filter(item => item !== undefined)
            ]
            const isNestedArray = (element) => Array.isArray(element);
            let flattenedData = newArray?.map(sublist => sublist?.filter(item => !isNestedArray(item)));

            return flattenedData;
        });

        setPreMedicalRecordFileName((prevArray) => {
            const newArray = [...prevArray];

            newArray[indexValue] = [
                ...(newArray[indexValue] || []),
                ...newMedicalRecordFileName.filter(item => item !== undefined)
            ]
            const isNestedArray = (element) => Array.isArray(element);
            let flattenedData = newArray?.map(sublist => sublist?.filter(item => !isNestedArray(item)));
            return flattenedData;
        });



    }

    const onClickAddBtn = (e) => {
        e.preventDefault()
        const uuid = GenerateUUID();
        setMedicalProviderCounter((prevCounter) => {
            const newCounter = prevCounter.concat(uuid);
            return newCounter.filter((item) => item !== ',');
        });
    }

    const onPreMedicalClickAddBtn = (e) => {
        e.preventDefault()
        const uuid = GenerateUUID();
        setPreMedicalProviderCounter((prevCounter) => {
            const newCounter = prevCounter.concat(uuid);
            return newCounter.filter((item) => item !== ',');
        });
    }

    const onClickMinusBtn = (e, value, index, innerIndex, medTypeIndex) => {
        const updatedInitialValues = { ...initialValues };
        const providerName = `${"medicalProviderandFacility" + index}`
        const medicalProviderName = `${"medicalType" + index}`
        if (updatedInitialValues[providerName] !== undefined) {
            updatedInitialValues[providerName] = '';
        }
        if (updatedInitialValues[medicalProviderName] !== undefined) {
            updatedInitialValues[medicalProviderName] = '';
        }
        setInitialValues(updatedInitialValues);

        e.preventDefault()
        const dataIndex = medicalProviderCounter.findIndex((element) => element === value);
        const outerIndex = index;
        const isIndexMinus = innerIndex === -1 ? 0 : innerIndex;
        if (medicalRecordsFile.length) {
            setMedicalRecordsFile((prevMedicalRecordsFiles) => {
                let updatedValues = [...prevMedicalRecordsFiles].map(subArray =>
                    subArray ? subArray.filter(value => value !== undefined) : []
                );

                if (updatedValues[outerIndex]?.length > 1) {

                    updatedValues[outerIndex] = []
                    // return updatedValues[outerIndex] = []

                } else {
                    updatedValues[outerIndex] = updatedValues[outerIndex]?.filter((_, i) => i !== isIndexMinus);
                }

                if (updatedValues[outerIndex]?.length === 0) {
                    updatedValues.splice(outerIndex, 1);
                }

                return updatedValues;
            });

        }


        if (multipleMedicalProviderandFacitlityName.length) {
            setMultipleMedicalProviderandFacitlityName((prevMedicalRecordsFiles) => {
                let updatedValues = [...prevMedicalRecordsFiles]
                updatedValues = updatedValues.filter((_, index) => index !== outerIndex)
                if (updatedValues[outerIndex]?.length === 0) {
                    updatedValues.splice(outerIndex, 1);
                    // setMultipleMedicalProviderandFacitlityName([])
                }

                return updatedValues;
            });
        }


        if (medicalRecordFileName.length) {
            setMedicalRecordFileName((prevMedicalRecordFileName) => {
                let updatedValues = [...prevMedicalRecordFileName].map(subArray =>
                    subArray ? subArray.filter(value => value !== undefined) : []
                );
                if (updatedValues[outerIndex]?.length > 1) {

                    updatedValues[outerIndex] = []
                    //  return updatedValues[outerIndex] = []
                } else {
                    updatedValues[outerIndex] = updatedValues[outerIndex]?.filter((_, i) => i !== isIndexMinus);
                }
                if (updatedValues[outerIndex]?.length === 0) {
                    updatedValues?.splice(outerIndex, 1);
                }
                return updatedValues;
            });
        }
        if (medicalBills.length) {
            console.log(medicalBills)
            setMedicalBills((prevMedicalRecordsFiles) => {
                const updatedValues = [...prevMedicalRecordsFiles].map(subArray =>
                    subArray ? subArray.filter(value => value !== undefined) : []
                );

                if (updatedValues[outerIndex]?.length > 1) {

                    updatedValues[outerIndex] = []
                    //  return updatedValues[outerIndex] = []
                } else {

                    updatedValues[outerIndex] = updatedValues[outerIndex]?.filter((_, i) => i !== 0);
                    // updatedValues.filter((_, index) => index !== outerIndex)

                }
                if (updatedValues[outerIndex]?.length === 0) {
                    updatedValues.splice(outerIndex, 1);
                    // setMedicalBills([])
                }

                return updatedValues;
            });
        }

        if (medicalBillName.length) {

            setMedicalBillName((prevMedicalRecordFileName) => {
                const updatedValues = [...prevMedicalRecordFileName].map(subArray =>
                    subArray ? subArray.filter(value => value !== undefined) : []
                );
                if (updatedValues[outerIndex]?.length > 1) {

                    updatedValues[outerIndex] = []
                    //   return updatedValues[outerIndex] = []
                } else {
                    updatedValues[outerIndex] = updatedValues[outerIndex]?.filter((_, i) => i !== 0);
                    // updatedValues.filter((_, index) => index !== outerIndex)
                }
                if (updatedValues[outerIndex]?.length === 0) {
                    updatedValues.splice(outerIndex, 1);
                    // setMedicalBillName([])
                }
                return updatedValues;
            });
        }
        setMedicalProviderCounter((preValues) => {
            const updatedData = [...preValues].filter((_, isIndexMinus) => isIndexMinus !== undefined)
            const filteredData = updatedData?.filter((element, i) => i !== dataIndex);

            return filteredData
        })
    }


    const onClickPreMedicalMinusBtn = (e, value, index, innerIndex) => {
        debugger
        e.preventDefault()
        const dataIndex = premedicalProviderCounter.findIndex((element) => element === value);
        const outerIndex = index
        if (premedicalRecordsFile.length) {
            setPreMedicalRecordsFile((prevMedicalRecordsFiles) => {
                let updatedValues = [...prevMedicalRecordsFiles].map(subArray =>
                    subArray ? subArray.filter(value => value !== undefined) : []
                );

                if (updatedValues[outerIndex]?.length > 1) {

                    updatedValues[outerIndex] = updatedValues[outerIndex].forEach((x, index) => [])
                    // return updatedValues[outerIndex] = []

                } else {
                    updatedValues[outerIndex] = updatedValues[outerIndex]?.filter((_, i) => i !== innerIndex);
                }

                if (updatedValues[outerIndex]?.length === 0) {
                    updatedValues.splice(outerIndex, 1);
                }

                return updatedValues;
            });

        }


        if (multipleMedicalProviderandFacitlityName.length) {
            setPreMultipleMedicalProviderandFacitlityName((prevMedicalRecordsFiles) => {
                let updatedValues = [...prevMedicalRecordsFiles]
                updatedValues = updatedValues.filter((_, index) => index !== outerIndex)
                if (updatedValues[outerIndex]?.length === 0) {
                    updatedValues.splice(outerIndex, 1);
                    // setMultipleMedicalProviderandFacitlityName([])
                }

                return updatedValues;
            });
        }


        if (premedicalRecordFileName.length) {
            setPreMedicalRecordFileName((prevMedicalRecordFileName) => {
                let updatedValues = [...prevMedicalRecordFileName].map(subArray =>
                    subArray ? subArray.filter(value => value !== undefined) : []
                );
                if (updatedValues[outerIndex]?.length > 1) {

                    updatedValues[outerIndex] = updatedValues[outerIndex].forEach((x, index) => [])
                    //  return updatedValues[outerIndex] = []
                } else {
                    updatedValues[outerIndex] = updatedValues[outerIndex]?.filter((_, i) => i !== innerIndex);
                }

                if (updatedValues[outerIndex]?.length === 0) {
                    updatedValues?.splice(outerIndex, 1);
                }
                return updatedValues;
            });
        }

        setPreMedicalProviderCounter((preValues) => {
            const updatedData = [...preValues].filter((_, innerIndex) => innerIndex !== undefined)
            const filteredData = updatedData?.filter((element, i) => i !== dataIndex);

            return filteredData
        })
    }

    const deleteMedicalBill = (e, outerIndex, index) => {
        e.preventDefault()

        setMedicalBills((prevMedicalRecordsFiles) => {
            const updatedValues = [...prevMedicalRecordsFiles];
            updatedValues[outerIndex] = updatedValues[outerIndex].filter((item, i) => item !== undefined);
            updatedValues[outerIndex] = updatedValues[outerIndex].filter((_, i) => i !== index);
            return updatedValues;
        });

        setMedicalBillName((prevMedicalRecordFileName) => {
            const updatedValues = [...prevMedicalRecordFileName];
            updatedValues[outerIndex] = updatedValues[outerIndex].filter((_, i) => i !== index);
            return updatedValues;
        });

    }

    const deleteMedicalRecord = (e, outerIndex, index) => {
        setMedicalRecordsFile((prevMedicalRecordsFiles) => {
            const updatedValues = [...prevMedicalRecordsFiles]
            updatedValues[outerIndex] = updatedValues[outerIndex].filter((item, i) => item !== undefined);
            updatedValues[outerIndex] = updatedValues[outerIndex].filter((item, i) => i !== index);
            return updatedValues;
        });


        setMedicalRecordFileName((prevMedicalRecordFileName) => {
            const updatedValues = [...prevMedicalRecordFileName];
            updatedValues[outerIndex] = updatedValues[outerIndex].filter((item, i) => i !== index);
            return updatedValues;
        });
    };

    const deletePreMedicalRecord = (e, outerIndex, index) => {
        setPreMedicalRecordsFile((prevMedicalRecordsFiles) => {
            const updatedValues = [...prevMedicalRecordsFiles]
            updatedValues[outerIndex] = updatedValues[outerIndex].filter((item, i) => item !== undefined);
            updatedValues[outerIndex] = updatedValues[outerIndex].filter((item, i) => i !== index);
            return updatedValues;
        });


        setPreMedicalRecordFileName((prevMedicalRecordFileName) => {
            const updatedValues = [...prevMedicalRecordFileName];
            updatedValues[outerIndex] = updatedValues[outerIndex].filter((item, i) => i !== index);
            return updatedValues;
        });
    };

    const validatoionSchema = Yup.object().shape({
        monthlyamount: Yup.string().required('Amount is required'),
        annualamount: Yup.string().required('Amount is required'),
        gender: Yup.string().required('Gender is required'),
        age: Yup.string().required('Age is required'),
    })
    const [initialValues, setInitialValues] = useState({
        amount: '' || InjuryData?.amount,
        gender: '' || InjuryData?.gender,
        age: '' || InjuryData?.age,
        accidentScenes: {},
        medicalBills: {},
        medicalType: {},
        premedicalType: {},
        medicalProviderandFacility: {},
        premedicalProviderandFacility: {},
    });


    // Loop to initialize accidentScenes, medicalBills, medicalType, premedicalType, medicalProviderandFacility, and premedicalProviderandFacility
    for (let i = 0; i <= 30; i++) {
        initialValues[`accidentScenes${i}`] = '';
        initialValues[`medicalBills${i}`] = '';
        initialValues[`medicalType${i}`] = initialValues?.[`medicalType${i}`] || '';
        initialValues[`premedicalType${i}`] = initialValues?.[`premedicalType${i}`] || '';
        initialValues[`medicalProviderandFacility${i}`] = initialValues?.[`medicalProviderandFacility${i}`] || '';
        initialValues[`premedicalProviderandFacility${i}`] = initialValues?.[`premedicalProviderandFacility${i}`] || '';
    }
    console.log(InjuryData, "InjuryData", initialValues);


    return (
        <>
            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                // validationSchema={validatoionSchema}

                onSubmit={(values, { setSubmitting, setErrors, errors }) => {
                    console.log(values, "in values ", setSubmitting);
                    console.log(multipleMedicalProviderandFacitlityName, medicalBills, "multipleMedicalProviderandFacitlityName");
                    let allHaveMedicalProviderNames = true;
                    values.medicalBillRecordsPdf = pdfFile;
                    values.medicalRecordsFileRecord = medicalRecordsFile;
                    values.medicalBillsRecord = medicalBills?.filter(x => x != undefined);
                    values.medicalBillsData = medicalBills;
                    values.premedicalRecordsFileRecord = premedicalRecordsFile;

                    values.medicalRecordsFile = medicalProvidersObject(medicalRecordsFile, multipleMedicalProviderandFacitlityName, medicalBills, values);
                    values.preMecialRecordsFile = preMedicalProvidersObject(premedicalRecordsFile, premultipleMedicalProviderandFacitlityName, values);
                    setValues({ injury: values })

                    if (isSubmitting) {
                        console.log(values, "in values ");
                        dispatch(storeInjuryAnalysisEstern(
                            values
                        ));
                    } else {
                        console.log(values, "in values ");
                        dispatch(storeInjuryAnalysisEstern(
                            values
                        ));
                        onSubmit({ injury: values });
                    }
                    setIsSubmitting(false); // Reset the state after submission



                }}
            >
                {({ submitForm }) => (
                    <Form>
                        <div className="add-form p-0">
                            <div className="card">
                                {/* <h2 className="title">Past and Future Pain and Suffering Calculation</h2>
                                <div className="row">
                                    <div className='row'>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <Field name="age" placeholder="Write age..." label="Age" type="number" component={TextInput} />
                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <Field name="gender"
                                                    label="Gender"
                                                    component={Dropdown}
                                                    defaultOption="Select Gender"
                                                    options={Constants.Dropdowns.MrMrs}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <Field name="monthlyamount"
                                                    label="Past Monthly Non-Economic Amount"
                                                    placeholder="Enter amount"
                                                    component={TextInput}
                                                    shouldUserRenderIcon={<>   <span style={{ position: 'absolute', color: '#18479a', paddingLeft: '18px', paddingTop: '12px' }}>$</span>    </>}
                                                    shouldDollarRender={true}
                                                    isDollarSignRender={true}

                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <Field name="annualamount"
                                                    label="Future Annual Non-Economic Amount"
                                                    placeholder="Enter amount"
                                                    component={TextInput}
                                                    shouldUserRenderIcon={<>   <span style={{ position: 'absolute', color: '#18479a', paddingLeft: '18px', paddingTop: '12px' }}>$</span>    </>}
                                                    shouldDollarRender={true}
                                                    isDollarSignRender={true}

                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                                <h2>Post-Accident Medical Providers</h2>
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className='mt-2'>
                                            <Field name={`medicalProviderandFacility0`} placeholder="Write Medical Provider Name" label="Medical Provider / Facitlity" component={TextInput} />
                                        </div>
                                        <span style={{ color: 'red' }}>{error.medicalProviderNames_0}</span>
                                    </div>
                                    <div className="col-md-3" style={{ paddingTop: '2px', paddingLeft: '5px' }}>
                                        <div className='mt-2' >
                                            <label>Upload Provider Medical Records <i className="fa-solid fa-upload" style={{ paddingLeft: '11.8rem' }}></i>
                                            </label><br></br>
                                            <RenderIf shouldRender={medicalRecordFileName[0]?.length}>
                                                <div style={{ marginLeft: '26rem' }}>
                                                    <i className='fa-solid fa-trash' style={{ position: 'absolute', width: '20px', height: '20px', paddingTop: '1.5rem' }} onClick={(e) => { deleteMedicalRecord(e, 0, 0) }}></i>
                                                </div>
                                            </RenderIf>
                                            <label htmlFor="fileInput0" style={{ cursor: 'pointer' }}>
                                                <i className="fa-solid fa-plus" style={{ position: 'absolute', paddingTop: '1.3rem', paddingLeft: '1rem' }}></i>
                                                <div className="fileInputStyle mt-2" style={{ backgroundColor: '#F2F2F2', borderRadius: '5px', height: '3rem', width: '28rem' }}>
                                                    <div style={{ textAlign: 'center', height: '1px', paddingTop: '10px' }}>

                                                        {medicalRecordFileName[0]?.length ? (medicalRecordFileName[0][1]?.length > 1 ? medicalRecordFileName[0][0] : medicalRecordFileName[0][0]) : 'Upload Medical Record'}
                                                    </div>
                                                </div>
                                            </label>

                                            <Field
                                                type="file"
                                                accept="application/pdf"
                                                id="fileInput0"
                                                name="fileInput0"
                                                style={{ display: 'none' }}
                                                multiple
                                                onChange={(e) => {
                                                    onMedicalRecordsFile(e)
                                                }}
                                            />
                                        </div>
                                        <RenderIf shouldRender={medicalRecordFileName[0]?.length}>
                                            {
                                                medicalRecordsFile?.filter((item, filterIndex) => filterIndex === 0 && item !== undefined)?.map((values, outerIndex) => (
                                                    values?.slice(1).map((record, innerIndex) => (
                                                        record ? (
                                                            <>
                                                                <span style={{ display: 'none' }}>{innerIndex = innerIndex + 1}</span>
                                                                <div style={{ marginLeft: '26rem' }}>
                                                                    <i className='fa-solid fa-trash' style={{ position: 'absolute', width: '20px', height: '20px', paddingTop: '1.5rem' }} onClick={(e) => { deleteMedicalRecord(e, 0, innerIndex) }}></i>
                                                                </div>
                                                                <div className='mt-2' key={`${outerIndex}_${innerIndex}`} style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', width: '28rem', height: '3rem', backgroundColor: '#F2F2F2', }}>
                                                                    <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', marginTop: '0.7rem', }}>{record?.name}</div>
                                                                </div>

                                                            </>
                                                        ) : ''
                                                    ))
                                                ))
                                            }
                                        </RenderIf >

                                    </div>
                                    <div className="col-md-3 mt-2" style={{ paddingTop: '2px', paddingLeft: '5px' }}>
                                        <Field name="medicalType0"
                                            label="Medical Record Type"
                                            component={Dropdown}
                                            defaultOption="Select Type"
                                            options={Constants.Dropdowns.FileType}
                                        />
                                    </div>

                                    <div className="col-md-3" style={{ paddingTop: '2px', paddingLeft: '5px' }}>
                                        <div className='mt-2'>
                                            <label style={{ width: '30rem' }}>Upload Medical Bills <i className="fa-solid fa-upload" style={{ paddingLeft: '17.4rem' }} ></i>
                                            </label><br></br>
                                            <RenderIf shouldRender={medicalBillName[0]?.length}>
                                                <div style={{ marginLeft: '26rem' }}>
                                                    <i className='fa-solid fa-trash' style={{ position: 'absolute', width: '20px', height: '20px', paddingTop: '1.5rem' }} onClick={(e) => { deleteMedicalBill(e, 0, 0) }}></i>
                                                </div>
                                            </RenderIf>
                                            <label htmlFor="fileInputBill0" style={{ cursor: 'pointer' }}>
                                                <i className="fa-solid fa-plus" style={{ position: 'absolute', paddingTop: '1.3rem', paddingLeft: '1rem' }}></i>
                                                <div className="fileInputStyle mt-2" style={{ backgroundColor: '#F2F2F2', borderRadius: '5px', height: '3rem', width: '28rem' }}>
                                                    <div style={{ textAlign: 'center', height: '1px', paddingTop: '10px' }}>
                                                        {/* {medicalBillName[0] ? medicalBillName[0] : 'Upload Medical Bill'} */}
                                                        {medicalBillName[0]?.length ? (medicalBillName[0][1]?.length > 1 ? medicalBillName[0][0] : medicalBillName[0][0]) : 'Upload Medical Bill'}

                                                    </div>
                                                </div>
                                            </label>
                                            <Field
                                                type="file"
                                                accept="application/pdf"
                                                id="fileInputBill0"
                                                name="fileInputBill0"
                                                style={{ display: 'none' }}
                                                multiple
                                                onChange={(e) => onMedicalBillFileSelect(e)}
                                            />
                                            <RenderIf shouldRender={medicalBillName[0]?.length}>
                                                {
                                                    medicalBillName?.filter((item, filterIndex) => filterIndex === 0 && item !== undefined)?.map((values, outerIndex) => (
                                                        values?.slice(1).map((record, innerIndex) => (
                                                            record ? (
                                                                <>
                                                                    <div style={{ display: 'none' }}>{innerIndex = innerIndex + 1}</div>
                                                                    <div style={{ marginLeft: '26rem' }}>
                                                                        <i className='fa-solid fa-trash' style={{ position: 'absolute', width: '20px', height: '20px', paddingTop: '1.5rem' }} onClick={(e) => deleteMedicalBill(e, 0, innerIndex)}></i>
                                                                    </div>
                                                                    <div className='mt-2' key={`${outerIndex}_${innerIndex}`} style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', width: '28rem', height: '3rem', backgroundColor: '#F2F2F2', }}>
                                                                        <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', marginTop: '0.7rem', }}>{record}</div>
                                                                    </div>
                                                                </>
                                                            ) : ''
                                                        ))
                                                    ))
                                                }
                                            </RenderIf>
                                        </div>

                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-3" >
                                        <div className='' style={{ marginTop: '5px' }}>
                                            {/* <button className='add-btn-style' onClick={(e) => addMultipleMedicalRecordsandmedicalBillNameForSingleProvider(e, 1)} style={{ position: 'absolute', marginLeft: '20.6rem', width: '3.5rem', height: '3rem' }}><i style={{ color: 'white' }} className="fa-solid fa-plus"></i></button> */}
                                            <Field name={`medicalProviderandFacility1`} placeholder="Write Medical Provider Name" type="text" component={TextInput} />
                                            <span style={{ color: 'red' }}>{error.medicalProviderNames_1}</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3" style={{ paddingTop: '2px', paddingLeft: '5px' }}>
                                        <div className=''>
                                            <RenderIf shouldRender={medicalRecordFileName[1]?.length}>
                                                <div style={{ marginLeft: '26rem' }}>
                                                    <i className='fa-solid fa-trash' style={{ position: 'absolute', width: '20px', height: '20px', paddingTop: '1.4rem' }} onClick={(e) => deleteMedicalRecord(e, 1, 0)}></i>
                                                </div>
                                            </RenderIf>
                                            <label htmlFor="fileInput1" style={{ cursor: 'pointer' }}>
                                                <i className="fa-solid fa-plus" style={{ position: 'absolute', paddingTop: '1.3rem', paddingLeft: '1rem' }}></i>
                                                <div className="fileInputStyle mt-1" style={{ backgroundColor: '#F2F2F2', borderRadius: '5px', height: '3rem', width: '28rem' }}>
                                                    <div style={{ textAlign: 'center', height: '1px', paddingTop: '10px' }}>
                                                        {medicalRecordFileName[1]?.length ? (medicalRecordFileName[1][0]?.length > 1 ? medicalRecordFileName[1]?.filter(item => item !== undefined)[0] : medicalRecordFileName[1]?.filter(item => item !== undefined)[0]) : 'Upload Medical Record'}
                                                    </div>
                                                </div>
                                            </label>
                                            <Field
                                                type="file"
                                                accept="application/pdf"
                                                id="fileInput1"
                                                name="fileInput1"
                                                multiple
                                                style={{ display: 'none' }}
                                                onChange={(e) => onMedicalRecordsFile(e)}
                                            />
                                            <RenderIf shouldRender={medicalRecordFileName[1]?.length}>
                                                {
                                                    medicalRecordsFile?.filter((item, filterIndex) => filterIndex === 1 && item !== undefined)?.map((values, outerIndex) => (
                                                        values?.slice(1).map((record, innerIndex) => (
                                                            record ? (
                                                                <div>
                                                                    <span style={{ display: 'none' }}>{innerIndex = innerIndex + 1}</span>
                                                                    <div style={{ marginLeft: '26rem' }}>
                                                                        <i className='fa-solid fa-trash' style={{ position: 'absolute', width: '20px', height: '20px', paddingTop: '1.2rem' }} onClick={(e) => { e.stopPropagation(); deleteMedicalRecord(e, 1, innerIndex) }}></i>
                                                                    </div>
                                                                    <div className='mt-2' key={`${outerIndex}_${innerIndex}`} style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', width: '28rem', height: '3rem', backgroundColor: '#F2F2F2', }}>
                                                                        <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', marginTop: '0.7rem', }}>{record?.name}</div>

                                                                    </div>
                                                                </div>
                                                            ) : ''
                                                        ))
                                                    ))
                                                }
                                            </RenderIf>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mt-1" style={{ paddingLeft: '5px' }}>
                                        <Field name="medicalType1"
                                            // label="Medical Record Type"
                                            component={Dropdown}
                                            defaultOption="Select Type"
                                            options={Constants.Dropdowns.FileType}
                                        />
                                    </div>
                                    <div className="col-md-3" style={{ paddingLeft: '5px' }}>
                                        <div className=''>
                                            <RenderIf shouldRender={medicalBillName[1]?.length}>
                                                <div style={{ marginLeft: '26rem' }}>
                                                    <i className='fa-solid fa-trash' style={{ position: 'absolute', width: '20px', height: '20px', paddingTop: '1.3rem' }} onClick={(e) => { deleteMedicalBill(e, 1, 0) }}></i>
                                                </div>
                                            </RenderIf>
                                            <label htmlFor="fileInputBill1" style={{ cursor: 'pointer' }}>
                                                <i className="fa-solid fa-plus" style={{ position: 'absolute', paddingTop: '1.3rem', paddingLeft: '1rem' }}></i>
                                                <div className="fileInputStyle mt-1" style={{ backgroundColor: '#F2F2F2', borderRadius: '5px', height: '3rem', width: '28rem' }}>
                                                    <div style={{ textAlign: 'center', height: '1px', paddingTop: '10px' }}>
                                                        {medicalBillName[1]?.length ? (medicalBillName[1][0]?.length > 1 ? medicalBillName[1]?.filter(item => item !== undefined)[0] : medicalBillName[1]?.filter(item => item !== undefined)[0]) : 'Upload Medical Bill'}

                                                    </div>
                                                </div>
                                            </label>
                                            <Field
                                                type="file"
                                                accept="application/pdf"
                                                id="fileInputBill1"
                                                name="fileInputBill1"
                                                multiple
                                                style={{ display: 'none' }}
                                                onChange={(e) => onMedicalBillFileSelect(e)}
                                            />
                                            <RenderIf shouldRender={medicalBillName[1]?.length}>
                                                {
                                                    medicalBillName?.filter((item, filterIndex) => filterIndex === 1 && item !== undefined)?.map((values, outerIndex) => (
                                                        values?.slice(1).map((record, innerIndex) => (
                                                            record ? (
                                                                <div>
                                                                    <div style={{ marginLeft: '26rem' }}>
                                                                        <div style={{ display: 'none' }}>{innerIndex = innerIndex + 1}</div>
                                                                        <i className='fa-solid fa-trash' style={{ position: 'absolute', width: '20px', height: '20px', paddingTop: '1.3rem' }} onClick={(e) => deleteMedicalBill(e, 1, innerIndex)}></i>
                                                                    </div>
                                                                    <div className='mt-2' key={`${outerIndex}_${innerIndex}`} style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', width: '28rem', height: '3rem', backgroundColor: '#F2F2F2', }}>

                                                                        <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', marginTop: '0.7rem', }}>{record}</div>
                                                                    </div>
                                                                </div>
                                                            ) : ''
                                                        ))
                                                    ))
                                                }
                                            </RenderIf>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-3">
                                        <div className='' style={{ marginTop: '4px' }}>

                                            <Field name={`medicalProviderandFacility2`} placeholder="Write Medical Provider Name" type="text" component={TextInput} />
                                            <span style={{ color: 'red' }}>{error.medicalProviderNames_2}</span>
                                        </div>
                                    </div>
                                    <div className="col-md-3" style={{ paddingLeft: '5px' }}>
                                        <div className=''>
                                            {/* <i className="fa-solid fa-trash" style={{ position: 'absolute', paddingTop: '1.3rem', paddingLeft: '1rem' }} onClick={() => openMedicalFileModal(2)}></i> */}
                                            <RenderIf shouldRender={medicalRecordFileName[2]?.length}>
                                                <div style={{ marginLeft: '26rem' }}>
                                                    <i className='fa-solid fa-trash' style={{ position: 'absolute', width: '20px', height: '20px', paddingTop: '1.2rem' }} onClick={(e) => deleteMedicalRecord(e, 2, 0)}></i>
                                                </div>
                                            </RenderIf>
                                            <label htmlFor="fileInput2" style={{ cursor: 'pointer' }}>
                                                <i className="fa-solid fa-plus" style={{ position: 'absolute', paddingTop: '1.3rem', paddingLeft: '1rem' }}></i>
                                                <div className="fileInputStyle mt-1" style={{ backgroundColor: '#F2F2F2', borderRadius: '5px', height: '3rem', width: '28rem' }}>
                                                    <div style={{ textAlign: 'center', height: '1px', paddingTop: '10px' }}>
                                                        {medicalRecordFileName[2]?.length ? (medicalRecordFileName[2]?.length > 1 ? medicalRecordFileName[2][0] : medicalRecordFileName[2][0]) : 'Upload Medical Record'}
                                                    </div>
                                                </div>
                                            </label>
                                            <Field
                                                type="file"
                                                accept="application/pdf"
                                                id="fileInput2"
                                                name="fileInput2"
                                                multiple
                                                style={{ display: 'none' }}
                                                onChange={(e) => onMedicalRecordsFile(e)}
                                            />
                                            <RenderIf shouldRender={medicalRecordFileName[2]?.length}>
                                                {
                                                    medicalRecordsFile?.filter((item, filterIndex) => filterIndex === 2 && item !== undefined)?.map((values, outerIndex) => (
                                                        values?.slice(1).map((record, innerIndex) => (
                                                            record ? (
                                                                <div>
                                                                    <div style={{ display: 'none' }}>{innerIndex + 1}</div>
                                                                    <div style={{ marginLeft: '26rem' }}>
                                                                        <i className='fa-solid fa-trash' style={{ position: 'absolute', width: '20px', height: '20px', paddingTop: '1.2rem' }} onClick={(e) => deleteMedicalRecord(e, 2, innerIndex)}></i>
                                                                    </div>
                                                                    <div className='mt-2' key={`${outerIndex}_${innerIndex}`} style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', width: '28rem', height: '3rem', backgroundColor: '#F2F2F2', }}>
                                                                        <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', marginTop: '0.7rem', }}>{record?.name}</div>
                                                                    </div>
                                                                </div>
                                                            ) : ''
                                                        ))
                                                    ))
                                                }
                                            </RenderIf>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mt-1" style={{ paddingLeft: '5px' }}>
                                        <Field name="medicalType2"
                                            // label="Medical Record Type"
                                            component={Dropdown}
                                            defaultOption="Select Type"
                                            options={Constants.Dropdowns.FileType}
                                        />
                                    </div>
                                    <div className="col-md-3" style={{ paddingLeft: '5px' }}>
                                        <div className=''>
                                            <RenderIf shouldRender={medicalBillName[2]?.length}>
                                                <div style={{ marginLeft: '26rem' }}>
                                                    <i className='fa-solid fa-trash' style={{ position: 'absolute', width: '20px', height: '20px', paddingTop: '1.3rem' }} onClick={(e) => { deleteMedicalBill(e, 2, 0) }}></i>
                                                </div>
                                            </RenderIf>
                                            <label htmlFor="fileInputBill2" style={{ cursor: 'pointer' }}>
                                                <i className="fa-solid fa-plus" style={{ position: 'absolute', paddingTop: '1.3rem', paddingLeft: '1rem' }}></i>
                                                <div className="fileInputStyle mt-1" style={{ backgroundColor: '#F2F2F2', borderRadius: '5px', height: '3rem', width: '28rem' }}>
                                                    <div style={{ textAlign: 'center', height: '1px', paddingTop: '10px' }}>
                                                        {medicalBillName[2]?.length ? (medicalBillName[2]?.length > 1 ? medicalBillName[2][0] : medicalBillName[2][0]) : 'Upload Medical Bill'}
                                                    </div>
                                                </div>
                                            </label>
                                            <Field
                                                type="file"
                                                accept="application/pdf"
                                                id="fileInputBill2"
                                                name="fileInputBill2"
                                                multiple
                                                style={{ display: 'none' }}
                                                onChange={(e) => onMedicalBillFileSelect(e)}
                                            />
                                            <RenderIf shouldRender={medicalBillName[2]?.length}>
                                                {
                                                    medicalBillName?.filter((item, filterIndex) => filterIndex === 2 && item !== undefined)?.map((values, outerIndex) => (
                                                        values?.slice(1).map((record, innerIndex) => (
                                                            record ? (
                                                                <div>
                                                                    <div style={{ marginLeft: '26rem' }}>
                                                                        <div style={{ display: 'none' }}>{innerIndex = innerIndex + 1}</div>
                                                                        <i className='fa-solid fa-trash' style={{ position: 'absolute', width: '20px', height: '20px', paddingTop: '1.3rem' }} onClick={(e) => deleteMedicalBill(e, 2, innerIndex)}></i>
                                                                    </div>
                                                                    <div className='mt-2' key={`${outerIndex}_${innerIndex}`} style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', width: '28rem', height: '3rem', backgroundColor: '#F2F2F2', }}>

                                                                        <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', marginTop: '0.7rem', }}>{record}</div>
                                                                    </div>
                                                                </div>
                                                            ) : ''
                                                        ))
                                                    ))
                                                }
                                            </RenderIf>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-3">
                                        <div className='' style={{ marginTop: '4px' }}>
                                            <Field name={`medicalProviderandFacility3`} placeholder="Write Medical Provider Name" type="text" component={TextInput} />
                                            <span style={{ color: 'red' }}>{error.medicalProviderNames_3}</span>
                                        </div>

                                    </div>
                                    <div className="col-md-3" style={{ paddingLeft: '5px' }}>
                                        <div className=''>
                                            {/* <i className="fa-solid fa-trash" style={{ position: 'absolute', paddingTop: '1.3rem', paddingLeft: '1rem' }} onClick={() => openMedicalFileModal(3)}></i> */}
                                            <RenderIf shouldRender={medicalRecordFileName[3]?.length}>
                                                <div style={{ marginLeft: '26rem' }}>
                                                    <i className='fa-solid fa-trash' style={{ position: 'absolute', width: '20px', height: '20px', paddingTop: '1.2rem' }} onClick={(e) => deleteMedicalRecord(e, 3, 0)}></i>
                                                </div>
                                            </RenderIf>
                                            <label htmlFor="fileInput3" style={{ cursor: 'pointer' }}>
                                                <i className="fa-solid fa-plus" style={{ position: 'absolute', paddingTop: '1.3rem', paddingLeft: '1rem' }}></i>
                                                <div className="fileInputStyle mt-1" style={{ backgroundColor: '#F2F2F2', borderRadius: '5px', height: '3rem', width: '28rem' }}>
                                                    <div style={{ textAlign: 'center', height: '1px', paddingTop: '10px' }}>
                                                        {medicalRecordFileName[3]?.length ? (medicalRecordFileName[3].length > 1 ? medicalRecordFileName[3][0] : medicalRecordFileName[3][0]) : 'Upload Medical Record'}
                                                    </div>
                                                </div>
                                            </label>
                                            <Field
                                                type="file"
                                                accept="application/pdf"
                                                id="fileInput3"
                                                name="fileInput3"
                                                multiple
                                                style={{ display: 'none' }}
                                                onChange={(e) => onMedicalRecordsFile(e)}
                                            />
                                            <RenderIf shouldRender={medicalRecordFileName[3]?.length}>
                                                {
                                                    medicalRecordsFile?.filter((item, filterIndex) => filterIndex === 3 && item !== undefined)?.map((values, outerIndex) => (
                                                        values?.slice(1).map((record, innerIndex) => (
                                                            record ? (
                                                                <div>
                                                                    <div>
                                                                        <span style={{ display: 'none' }}>{innerIndex = innerIndex + 1}</span>
                                                                        <div style={{ marginLeft: '26rem' }}>
                                                                            <i className='fa-solid fa-trash' style={{ position: 'absolute', width: '20px', height: '20px', paddingTop: '1.2rem' }} onClick={(e) => deleteMedicalRecord(e, 3, innerIndex)}></i>
                                                                        </div>
                                                                    </div>
                                                                    <div className='mt-2' key={`${outerIndex}_${innerIndex}`} style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', width: '28rem', height: '3rem', backgroundColor: '#F2F2F2', }}>
                                                                        <div style={{ display: 'none' }}>{innerIndex + 1}</div>
                                                                        <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', marginTop: '0.7rem', }}>{record?.name}</div>
                                                                    </div>
                                                                </div>
                                                            ) : ''
                                                        ))
                                                    ))
                                                }
                                            </RenderIf>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mt-1" style={{ paddingLeft: '5px' }}>
                                        <Field name="medicalType3"
                                            // label="Medical Record Type"
                                            component={Dropdown}
                                            defaultOption="Select Type"
                                            options={Constants.Dropdowns.FileType}
                                        />
                                    </div>
                                    <div className="col-md-3" style={{ paddingLeft: '5px' }}>
                                        <div className=''>
                                            <RenderIf shouldRender={medicalBillName[3]?.length}>
                                                <div style={{ marginLeft: '26rem' }}>
                                                    <i className='fa-solid fa-trash' style={{ position: 'absolute', width: '20px', height: '20px', paddingTop: '1.5rem' }} onClick={(e) => { deleteMedicalBill(e, 3, 0) }}></i>
                                                </div>
                                            </RenderIf>
                                            <label htmlFor="fileInputBill3" style={{ cursor: 'pointer' }}>
                                                <i className="fa-solid fa-plus" style={{ position: 'absolute', paddingTop: '1.3rem', paddingLeft: '1rem' }}></i>
                                                <div className="fileInputStyle mt-1" style={{ backgroundColor: '#F2F2F2', borderRadius: '5px', height: '3rem', width: '28rem' }}>
                                                    <div style={{ textAlign: 'center', height: '1px', paddingTop: '10px' }}>
                                                        {medicalBillName[3]?.length ? (medicalBillName[3]?.length > 1 ? medicalBillName[3][0] : medicalBillName[3][0]) : 'Upload Medical Bill'}
                                                    </div>
                                                </div>
                                            </label>
                                            <Field
                                                type="file"
                                                accept="application/pdf"
                                                id="fileInputBill3"
                                                name="fileInputBill3"
                                                multiple
                                                style={{ display: 'none' }}
                                                onChange={(e) => onMedicalBillFileSelect(e)}
                                            />
                                            <RenderIf shouldRender={medicalBillName[3]?.length}>
                                                {
                                                    medicalBillName?.filter((item, filterIndex) => filterIndex === 3 && item !== undefined)?.map((values, outerIndex) => (
                                                        values?.slice(1).map((record, innerIndex) => (
                                                            record ? (
                                                                <div>
                                                                    <div style={{ marginLeft: '26rem' }}>
                                                                        <div style={{ display: 'none' }}>{innerIndex = innerIndex + 1}</div>
                                                                        <i className='fa-solid fa-trash' style={{ position: 'absolute', width: '20px', height: '20px', paddingTop: '1.3rem' }} onClick={(e) => deleteMedicalBill(e, 3, innerIndex)}></i>
                                                                    </div>
                                                                    <div className='mt-2' key={`${outerIndex}_${innerIndex}`} style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', width: '28rem', height: '3rem', backgroundColor: '#F2F2F2', }}>

                                                                        <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', marginTop: '0.7rem', }}>{record}</div>
                                                                    </div>
                                                                </div>
                                                            ) : ''
                                                        ))
                                                    ))
                                                }
                                            </RenderIf>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-3" >
                                        <div className='' style={{ marginTop: '2px' }}>
                                            <Field name={`medicalProviderandFacility4`} placeholder="Write Medical Provider Name" type="text" component={TextInput} />
                                            <span style={{ color: 'red' }}>{error.medicalProviderNames_4}</span>
                                        </div>

                                    </div>
                                    <div className="col-md-3" style={{ paddingLeft: '5px' }}>
                                        <div className=''>
                                            <RenderIf shouldRender={medicalRecordFileName[4]?.length}>
                                                <div style={{ marginLeft: '26rem' }}>
                                                    <i className='fa-solid fa-trash' style={{ position: 'absolute', width: '20px', height: '20px', paddingTop: '1.2rem' }} onClick={(e) => deleteMedicalRecord(e, 4, 0)}></i>
                                                </div>
                                            </RenderIf>
                                            <label htmlFor="fileInput4" style={{ cursor: 'pointer' }}>
                                                <i className="fa-solid fa-plus" style={{ position: 'absolute', paddingTop: '1.3rem', paddingLeft: '1rem' }}></i>
                                                <div className="fileInputStyle mt-1" style={{ backgroundColor: '#F2F2F2', borderRadius: '5px', height: '3rem', width: '28rem' }}>
                                                    <div style={{ textAlign: 'center', height: '1px', paddingTop: '10px' }}>
                                                        {medicalRecordFileName[4]?.length ? (medicalRecordFileName[4].length > 1 ? medicalRecordFileName[4][0] : medicalRecordFileName[4][0]) : 'Upload Medical Record'}
                                                    </div>
                                                </div>
                                            </label>
                                            <Field
                                                type="file"
                                                accept="application/pdf"
                                                id="fileInput4"
                                                name="fileInput4"
                                                multiple
                                                style={{ display: 'none' }}
                                                onChange={(e) => onMedicalRecordsFile(e)}
                                            />
                                            <RenderIf shouldRender={medicalRecordFileName[4]?.length}>
                                                {
                                                    medicalRecordsFile?.filter((item, filterIndex) => filterIndex === 4 && item !== undefined)?.map((values, outerIndex) => (
                                                        values?.slice(1).map((record, innerIndex) => (
                                                            record ? (
                                                                <div>
                                                                    <div style={{ marginLeft: '26rem' }}>
                                                                        <div style={{ display: 'none' }}>{innerIndex + 1}</div>
                                                                        <i className='fa-solid fa-trash' style={{ position: 'absolute', width: '20px', height: '20px', paddingTop: '1.2rem' }} onClick={(e) => deleteMedicalRecord(e, 4, innerIndex)}></i>
                                                                    </div>
                                                                    <div className='mt-2' key={`${outerIndex}_${innerIndex}`} style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', width: '28rem', height: '3rem', backgroundColor: '#F2F2F2', }}>

                                                                        <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', marginTop: '0.7rem', }}>{record?.name}</div>
                                                                    </div>
                                                                </div>
                                                            ) : ''
                                                        ))
                                                    ))
                                                }
                                            </RenderIf>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mt-1" style={{ paddingLeft: '5px' }}>
                                        <Field name="medicalType4"
                                            // label="Medical Record Type"
                                            component={Dropdown}
                                            defaultOption="Select Type"
                                            options={Constants.Dropdowns.FileType}
                                        />
                                    </div>
                                    <div className="col-md-3" style={{ paddingLeft: '5px' }}>
                                        <div className=''>
                                            <RenderIf shouldRender={medicalBillName[4]?.length}>
                                                <div style={{ marginLeft: '26rem' }}>
                                                    <i className='fa-solid fa-trash' style={{ position: 'absolute', width: '20px', height: '20px', paddingTop: '1.3rem' }} onClick={(e) => { deleteMedicalBill(e, 4, 0) }}></i>
                                                </div>
                                            </RenderIf>
                                            <label htmlFor="fileInputBill4" style={{ cursor: 'pointer' }}>
                                                <i className="fa-solid fa-plus" style={{ position: 'absolute', paddingTop: '1.3rem', paddingLeft: '1rem' }}></i>
                                                <div className="fileInputStyle mt-1" style={{ backgroundColor: '#F2F2F2', borderRadius: '5px', height: '3rem', width: '28rem' }}>
                                                    <div style={{ textAlign: 'center', height: '1px', paddingTop: '10px' }}>
                                                        {medicalBillName[4]?.length ? (medicalBillName[4]?.length > 1 ? medicalBillName[4][0] : medicalBillName[4][0]) : 'Upload Medical Bill'}
                                                    </div>
                                                </div>
                                            </label>
                                            <Field
                                                type="file"
                                                accept="application/pdf"
                                                id="fileInputBill4"
                                                name="fileInputBill4"
                                                multiple
                                                style={{ display: 'none' }}
                                                onChange={(e) => onMedicalBillFileSelect(e)}
                                            />
                                            <RenderIf shouldRender={medicalBillName[4]?.length}>
                                                {
                                                    medicalBillName?.filter((item, filterIndex) => filterIndex === 4 && item !== undefined)?.map((values, outerIndex) => (
                                                        values?.slice(1).map((record, innerIndex) => (
                                                            record ? (
                                                                <div>
                                                                    <div style={{ marginLeft: '26rem' }}>
                                                                        <div style={{ display: 'none' }}>{innerIndex = innerIndex + 1}</div>
                                                                        <i className='fa-solid fa-trash' style={{ position: 'absolute', width: '20px', height: '20px', paddingTop: '1.3rem' }} onClick={(e) => deleteMedicalBill(e, 4, innerIndex)}></i>
                                                                    </div>
                                                                    <div className='mt-2' key={`${outerIndex}_${innerIndex}`} style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', width: '28rem', height: '3rem', backgroundColor: '#F2F2F2', }}>

                                                                        <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', marginTop: '0.7rem', }}>{record}</div>
                                                                    </div>
                                                                </div>
                                                            ) : ''
                                                        ))
                                                    ))
                                                }
                                            </RenderIf>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-3">
                                        <div className='mt-2'>
                                            <Field name={`medicalProviderandFacility5`} placeholder="Write Medical Provider Name" type="text" component={TextInput} ref={medicalProviderName} />
                                            <span style={{ color: 'red' }}>{error.medicalProviderNames_5}</span>
                                        </div>
                                        {/* onInput={(e) => handleInput(e)} */}
                                    </div>
                                    <div className="col-md-3" style={{ paddingLeft: '5px' }}>
                                        <div className=''>
                                            <RenderIf shouldRender={medicalRecordFileName[5]?.length}>
                                                <div style={{ marginLeft: '26rem' }}>
                                                    <i className='fa-solid fa-trash' style={{ position: 'absolute', width: '20px', height: '20px', paddingTop: '1.2rem' }} onClick={(e) => deleteMedicalRecord(e, 5, 0)}></i>
                                                </div>
                                            </RenderIf>
                                            <label htmlFor="fileInput5" style={{ cursor: 'pointer' }}>
                                                <i className="fa-solid fa-plus" style={{ position: 'absolute', paddingTop: '1.3rem', paddingLeft: '1rem' }}></i>
                                                <div className="fileInputStyle mt-2" style={{ backgroundColor: '#F2F2F2', borderRadius: '5px', height: '3rem', width: '28rem' }}>
                                                    <div style={{ textAlign: 'center', height: '1px', paddingTop: '10px' }}>
                                                        {medicalRecordFileName[5]?.length ? (medicalRecordFileName[5].length > 1 ? medicalRecordFileName[5][0] : medicalRecordFileName[5][0]) : 'Upload Medical Record'}
                                                    </div>
                                                </div>
                                            </label>
                                            <Field
                                                type="file"
                                                accept="application/pdf"
                                                id="fileInput5"
                                                name="fileInput5"
                                                multiple
                                                style={{ display: 'none' }}
                                                onChange={(e) => onMedicalRecordsFile(e)}
                                            />
                                            <RenderIf shouldRender={medicalRecordFileName[5]?.length}>
                                                {
                                                    medicalRecordsFile?.filter((item, filterIndex) => filterIndex === 5 && item !== undefined)?.map((values, outerIndex) => (
                                                        values?.slice(1).map((record, innerIndex) => (
                                                            record ? (
                                                                <div>
                                                                    <div style={{ display: 'none' }}>{innerIndex = innerIndex + 1}</div>
                                                                    <div style={{ marginLeft: '26rem' }}>
                                                                        <i className='fa-solid fa-trash' style={{ position: 'absolute', width: '20px', height: '20px', paddingTop: '1.2rem' }} onClick={(e) => deleteMedicalRecord(e, 5, innerIndex)}></i>
                                                                    </div>
                                                                    <div className='mt-2' key={`${outerIndex}_${innerIndex}`} style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', width: '28rem', height: '3rem', backgroundColor: '#F2F2F2', }}>
                                                                        <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', marginTop: '0.7rem', }}>{record?.name}</div>
                                                                    </div>
                                                                </div>
                                                            ) : ''
                                                        ))
                                                    ))
                                                }
                                            </RenderIf>
                                        </div>
                                    </div>
                                    <div className="col-md-3 mt-2" style={{ paddingLeft: '5px' }}>
                                        <Field name="medicalType5"
                                            // label="Medical Record Type"
                                            component={Dropdown}
                                            defaultOption="Select Type"
                                            options={Constants.Dropdowns.FileType}
                                        />
                                    </div>
                                    <div className="col-md-3" style={{ paddingLeft: '5px' }}>
                                        <RenderIf shouldRender={medicalBillName[5]?.length}>
                                            <div style={{ marginLeft: '21rem' }}>
                                                <i className='fa-solid fa-trash' style={{ position: 'absolute', width: '20px', height: '20px', paddingTop: '1.4rem' }} onClick={(e) => { deleteMedicalBill(e, 5, 0) }}></i>
                                            </div>
                                        </RenderIf>
                                        <div className='' style={{ display: 'flex' }}>

                                            <label htmlFor="fileInputBill5" style={{ cursor: 'pointer' }}>
                                                <i className="fa-solid fa-plus" style={{ position: 'absolute', paddingTop: '1.3rem', paddingLeft: '1rem' }}></i>
                                                <div className="fileInputStyle mt-2" style={{ backgroundColor: '#F2F2F2', borderRadius: '5px', height: '3rem', width: '23rem' }}>
                                                    <div style={{ textAlign: 'center', height: '1px', paddingTop: '10px' }}>
                                                        {medicalBillName[5]?.length ? (medicalBillName[5]?.length > 1 ? medicalBillName[5][0] : medicalBillName[5][0]) : 'Upload Medical Bill'}
                                                    </div>
                                                </div>
                                            </label>
                                            <Field
                                                type="file"
                                                accept="application/pdf"
                                                id="fileInputBill5"
                                                name="fileInputBill5"
                                                multiple
                                                style={{ display: 'none' }}
                                                onChange={(e) => onMedicalBillFileSelect(e)}
                                            />

                                            <div className='add-icon' style={{ marginLeft: '0.6rem' }}>
                                                <button className='add-btn-style' onClick={(e) => onClickAddBtn(e)}><i className="fa-solid fa-plus"></i></button>
                                            </div>
                                        </div>
                                        <div>
                                            <RenderIf shouldRender={medicalBillName[5]?.length}>
                                                {
                                                    medicalBillName?.filter((item, filterIndex) => filterIndex === 5 && item !== undefined)?.map((values, outerIndex) => (
                                                        values?.slice(1).map((record, innerIndex) => (
                                                            record ? (
                                                                <div>
                                                                    <div style={{ marginLeft: '26rem' }}>
                                                                        <div style={{ display: 'none' }}>{innerIndex = innerIndex + 1}</div>
                                                                        <i className='fa-solid fa-trash' style={{ position: 'absolute', width: '20px', height: '20px', paddingTop: '1rem' }} onClick={(e) => deleteMedicalBill(e, 5, innerIndex)}></i>
                                                                    </div>
                                                                    <div className='mt-2' key={`${outerIndex}_${innerIndex}`} style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', width: '28rem', height: '3rem', backgroundColor: '#F2F2F2', }}>

                                                                        <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', marginTop: '0.7rem', }}>{record}</div>
                                                                    </div>
                                                                </div>
                                                            ) : ''
                                                        ))
                                                    ))
                                                }
                                            </RenderIf>
                                        </div>
                                    </div>

                                </div>

                                <RenderIf shouldRender={medicalProviderCounter}>
                                    <MedicalProvidersControl
                                        data={medicalProviderCounter}
                                        handleInput={(e, innerIndex) => handleInput(e, innerIndex)}
                                        onMedicalBillFileSelect={onMedicalBillFileSelect}
                                        medicalBillName={medicalBillName}
                                        medicalRecordFileName={medicalRecordFileName}
                                        onMedicalRecordsFile={onMedicalRecordsFile}
                                        onAddClickBtn={onClickAddBtn}
                                        setMedicalProviderCounter={setMedicalProviderCounter}
                                        medicalProviderCounter={medicalProviderCounter}
                                        medicalRecordsFile={medicalRecordsFile}
                                        setMedicalBillName={setMedicalBillName}
                                        medicalBills={medicalBills}
                                        setMedicalBills={setMedicalBills}
                                        setMedicalRecordFileName={setMedicalRecordFileName}
                                        multipleMedicalProviderandFacitlityName={multipleMedicalProviderandFacitlityName}
                                        setMultipleMedicalProviderandFacitlityName={setMultipleMedicalProviderandFacitlityName}
                                        onClickMinusBtn={(e, value, index, innerIndex, medTypeIndex) => onClickMinusBtn(e, value, index, innerIndex, medTypeIndex)}
                                        setMedicalRecordsFile={setMedicalRecordsFile} // Pass the setMedicalRecordsFile prop
                                        error={error}
                                        deleteMedicalBill={(e, outerIndex, innerIndex) => deleteMedicalBill(e, outerIndex, innerIndex)}
                                        deleteMedicalRecord={(e, outerIndex, innerIndex) => deleteMedicalRecord(e, outerIndex, innerIndex)}

                                    />

                                </RenderIf>


                                <h2 className='mt-4'>Pre-Accident Medical Providers</h2>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className='mt-2'>
                                            <Field name="premedicalProviderandFacility0" placeholder="Write Medical Provider Name" label="Medical Provider / Facitlity" component={TextInput} />
                                        </div>
                                        <span style={{ color: 'red' }}>{error.medicalProviderNames_0}</span>
                                    </div>
                                    <div className="col-md-4" style={{ paddingTop: '2px', paddingLeft: '5px' }}>
                                        <div className='mt-2' >
                                            <label>Upload Provider Medical Records <i className="fa-solid fa-upload" style={{ paddingLeft: '21.9rem' }}></i>
                                            </label><br></br>
                                            <RenderIf shouldRender={premedicalRecordFileName[0]?.length}>
                                                <div style={{ marginLeft: '36rem' }}>
                                                    <i className='fa-solid fa-trash' style={{ position: 'absolute', width: '20px', height: '20px', paddingTop: '1.5rem' }} onClick={(e) => { deletePreMedicalRecord(e, 0, 0) }}></i>
                                                </div>
                                            </RenderIf>
                                            <label htmlFor="prefileInput0" style={{ cursor: 'pointer' }}>
                                                <i className="fa-solid fa-plus" style={{ position: 'absolute', paddingTop: '1.3rem', paddingLeft: '1rem' }}></i>
                                                <div className="fileInputStyle mt-2" style={{ backgroundColor: '#F2F2F2', borderRadius: '5px', height: '3rem', width: '38rem' }}>
                                                    <div style={{ textAlign: 'center', height: '1px', paddingTop: '10px' }}>
                                                        {/* <RenderIf shouldRender={}></> */}
                                                        {premedicalRecordFileName[0]?.length ? (premedicalRecordFileName[0][1]?.length > 1 ? premedicalRecordFileName[0][0] : premedicalRecordFileName[0][0]) : 'Upload Medical Record'}
                                                    </div>

                                                    {/* </div> */}
                                                </div>
                                            </label>

                                            <Field
                                                type="file"
                                                accept="application/pdf"
                                                id="prefileInput0"
                                                name="prefileInput0"
                                                style={{ display: 'none' }}
                                                multiple
                                                onChange={(e) => {
                                                    onPreMedicalRecordsFile(e)
                                                }}
                                            />
                                        </div>
                                        <RenderIf shouldRender={premedicalRecordFileName[0]?.length}>
                                            {
                                                premedicalRecordsFile?.filter((item, filterIndex) => filterIndex === 0 && item !== undefined)?.map((values, outerIndex) => (
                                                    values?.slice(1).map((record, innerIndex) => (
                                                        record ? (
                                                            <>
                                                                <span style={{ display: 'none' }}>{innerIndex = innerIndex + 1}</span>
                                                                <div style={{ marginLeft: '36rem' }}>
                                                                    <i className='fa-solid fa-trash' style={{ position: 'absolute', width: '20px', height: '20px', paddingTop: '1.5rem' }} onClick={(e) => { deletePreMedicalRecord(e, 0, innerIndex) }}></i>
                                                                </div>
                                                                <div className='mt-2' key={`${outerIndex}_${innerIndex}`} style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', width: '38rem', height: '3rem', backgroundColor: '#F2F2F2', }}>
                                                                    <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', marginTop: '0.7rem', }}>{record?.name}</div>
                                                                </div>

                                                            </>
                                                        ) : ''
                                                    ))
                                                ))
                                            }
                                        </RenderIf >

                                    </div>
                                    <div className="col-md-4 mt-2" style={{ paddingTop: '2px', paddingLeft: '5px' }}>
                                        <Field name="premedicalType0"
                                            label="Medical Record Type"
                                            component={Dropdown}
                                            defaultOption="Select Type"
                                            options={Constants.Dropdowns.FileType}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-4" >
                                        <div className='' style={{ marginTop: '5px' }}>
                                            <Field name={`premedicalProviderandFacility1`} placeholder="Write Medical Provider Name" type="text" component={TextInput} />
                                            <span style={{ color: 'red' }}>{error.medicalProviderNames_1}</span>
                                        </div>
                                    </div>
                                    <div className="col-md-4" style={{ paddingTop: '2px', paddingLeft: '5px' }}>
                                        <div className=''>
                                            <RenderIf shouldRender={premedicalRecordFileName[1]?.length}>
                                                <div style={{ marginLeft: '36rem' }}>
                                                    <i className='fa-solid fa-trash' style={{ position: 'absolute', width: '20px', height: '20px', paddingTop: '1.4rem' }} onClick={(e) => deletePreMedicalRecord(e, 1, 0)}></i>
                                                </div>
                                            </RenderIf>
                                            <label htmlFor="prefileInput1" style={{ cursor: 'pointer' }}>
                                                <i className="fa-solid fa-plus" style={{ position: 'absolute', paddingTop: '1.3rem', paddingLeft: '1rem' }}></i>
                                                <div className="fileInputStyle mt-1" style={{ backgroundColor: '#F2F2F2', borderRadius: '5px', height: '3rem', width: '38rem' }}>
                                                    <div style={{ textAlign: 'center', height: '1px', paddingTop: '10px' }}>
                                                        {premedicalRecordFileName[1]?.length ? (premedicalRecordFileName[1][0]?.length > 1 ? premedicalRecordFileName[1]?.filter(item => item !== undefined)[0] : premedicalRecordFileName[1]?.filter(item => item !== undefined)[0]) : 'Upload Medical Record'}
                                                    </div>
                                                </div>
                                            </label>
                                            <Field
                                                type="file"
                                                accept="application/pdf"
                                                id="prefileInput1"
                                                name="prefileInput1"
                                                multiple
                                                style={{ display: 'none' }}
                                                onChange={(e) => onPreMedicalRecordsFile(e)}
                                            />
                                            <RenderIf shouldRender={premedicalRecordFileName[1]?.length}>
                                                {
                                                    premedicalRecordsFile?.filter((item, filterIndex) => filterIndex === 1 && item !== undefined)?.map((values, outerIndex) => (
                                                        values?.slice(1).map((record, innerIndex) => (
                                                            record ? (
                                                                <div>
                                                                    <span style={{ display: 'none' }}>{innerIndex = innerIndex + 1}</span>
                                                                    <div style={{ marginLeft: '36rem' }}>
                                                                        <i className='fa-solid fa-trash' style={{ position: 'absolute', width: '20px', height: '20px', paddingTop: '1.2rem' }} onClick={(e) => { e.stopPropagation(); deletePreMedicalRecord(e, 1, innerIndex) }}></i>
                                                                    </div>
                                                                    <div className='mt-2' key={`${outerIndex}_${innerIndex}`} style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', width: '38rem', height: '3rem', backgroundColor: '#F2F2F2', }}>
                                                                        <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', marginTop: '0.7rem', }}>{record?.name}</div>

                                                                    </div>
                                                                </div>
                                                            ) : ''
                                                        ))
                                                    ))
                                                }
                                            </RenderIf>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mt-1" style={{ paddingLeft: '5px' }}>
                                        <Field name="premedicalType1"
                                            // label="Medical Record Type"
                                            component={Dropdown}
                                            defaultOption="Select Type"
                                            options={Constants.Dropdowns.FileType}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4" >
                                        <div className='' style={{ marginTop: '5px' }}>
                                            <Field name={`premedicalProviderandFacility2`} placeholder="Write Medical Provider Name" type="text" component={TextInput} />
                                            <span style={{ color: 'red' }}>{error.medicalProviderNames_1}</span>
                                        </div>
                                    </div>
                                    <div className="col-md-4" style={{ paddingTop: '2px', paddingLeft: '5px' }}>
                                        <div className=''>
                                            <RenderIf shouldRender={premedicalRecordFileName[2]?.length}>
                                                <div style={{ marginLeft: '36rem' }}>
                                                    <i className='fa-solid fa-trash' style={{ position: 'absolute', width: '20px', height: '20px', paddingTop: '1.4rem' }} onClick={(e) => deletePreMedicalRecord(e, 2, 0)}></i>
                                                </div>
                                            </RenderIf>
                                            <label htmlFor="prefileInput2" style={{ cursor: 'pointer' }}>
                                                <i className="fa-solid fa-plus" style={{ position: 'absolute', paddingTop: '1.3rem', paddingLeft: '1rem' }}></i>
                                                <div className="fileInputStyle mt-1" style={{ backgroundColor: '#F2F2F2', borderRadius: '5px', height: '3rem', width: '38rem' }}>
                                                    <div style={{ textAlign: 'center', height: '1px', paddingTop: '10px' }}>
                                                        {premedicalRecordFileName[2]?.length ? (premedicalRecordFileName[2][0]?.length > 1 ? premedicalRecordFileName[2]?.filter(item => item !== undefined)[0] : premedicalRecordFileName[2]?.filter(item => item !== undefined)[0]) : 'Upload Medical Record'}
                                                    </div>
                                                </div>
                                            </label>
                                            <Field
                                                type="file"
                                                accept="application/pdf"
                                                id="prefileInput2"
                                                name="prefileInput2"
                                                multiple
                                                style={{ display: 'none' }}
                                                onChange={(e) => onPreMedicalRecordsFile(e)}
                                            />
                                            <RenderIf shouldRender={premedicalRecordFileName[2]?.length}>
                                                {
                                                    premedicalRecordsFile?.filter((item, filterIndex) => filterIndex === 2 && item !== undefined)?.map((values, outerIndex) => (
                                                        values?.slice(1).map((record, innerIndex) => (
                                                            record ? (
                                                                <div>
                                                                    <span style={{ display: 'none' }}>{innerIndex = innerIndex + 1}</span>
                                                                    <div style={{ marginLeft: '36rem' }}>
                                                                        <i className='fa-solid fa-trash' style={{ position: 'absolute', width: '20px', height: '20px', paddingTop: '1.2rem' }} onClick={(e) => { e.stopPropagation(); deletePreMedicalRecord(e, 2, innerIndex) }}></i>
                                                                    </div>
                                                                    <div className='mt-2' key={`${outerIndex}_${innerIndex}`} style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', width: '38rem', height: '3rem', backgroundColor: '#F2F2F2', }}>
                                                                        <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', marginTop: '0.7rem', }}>{record?.name}</div>

                                                                    </div>
                                                                </div>
                                                            ) : ''
                                                        ))
                                                    ))
                                                }
                                            </RenderIf>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mt-1" style={{ paddingLeft: '5px' }}>
                                        <Field name="premedicalType2"
                                            // label="Medical Record Type"
                                            component={Dropdown}
                                            defaultOption="Select Type"
                                            options={Constants.Dropdowns.FileType}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4" >
                                        <div className='' style={{ marginTop: '5px' }}>
                                            <Field name={`premedicalProviderandFacility3`} placeholder="Write Medical Provider Name" type="text" component={TextInput} />
                                            <span style={{ color: 'red' }}>{error.medicalProviderNames_1}</span>
                                        </div>
                                    </div>
                                    <div className="col-md-4" style={{ paddingTop: '2px', paddingLeft: '5px' }}>
                                        <div className=''>
                                            <RenderIf shouldRender={premedicalRecordFileName[3]?.length}>
                                                <div style={{ marginLeft: '36rem' }}>
                                                    <i className='fa-solid fa-trash' style={{ position: 'absolute', width: '20px', height: '20px', paddingTop: '1.4rem' }} onClick={(e) => deletePreMedicalRecord(e, 3, 0)}></i>
                                                </div>
                                            </RenderIf>
                                            <label htmlFor="prefileInput3" style={{ cursor: 'pointer' }}>
                                                <i className="fa-solid fa-plus" style={{ position: 'absolute', paddingTop: '1.3rem', paddingLeft: '1rem' }}></i>
                                                <div className="fileInputStyle mt-1" style={{ backgroundColor: '#F2F2F2', borderRadius: '5px', height: '3rem', width: '38rem' }}>
                                                    <div style={{ textAlign: 'center', height: '1px', paddingTop: '10px' }}>
                                                        {premedicalRecordFileName[3]?.length ? (premedicalRecordFileName[3][0]?.length > 1 ? premedicalRecordFileName[3]?.filter(item => item !== undefined)[0] : premedicalRecordFileName[3]?.filter(item => item !== undefined)[0]) : 'Upload Medical Record'}
                                                    </div>
                                                </div>
                                            </label>
                                            <Field
                                                type="file"
                                                accept="application/pdf"
                                                id="prefileInput3"
                                                name="prefileInput3"
                                                multiple
                                                style={{ display: 'none' }}
                                                onChange={(e) => onPreMedicalRecordsFile(e)}
                                            />
                                            <RenderIf shouldRender={premedicalRecordFileName[3]?.length}>
                                                {
                                                    premedicalRecordsFile?.filter((item, filterIndex) => filterIndex === 3 && item !== undefined)?.map((values, outerIndex) => (
                                                        values?.slice(1).map((record, innerIndex) => (
                                                            record ? (
                                                                <div>
                                                                    <span style={{ display: 'none' }}>{innerIndex = innerIndex + 1}</span>
                                                                    <div style={{ marginLeft: '36rem' }}>
                                                                        <i className='fa-solid fa-trash' style={{ position: 'absolute', width: '20px', height: '20px', paddingTop: '1.2rem' }} onClick={(e) => { e.stopPropagation(); deletePreMedicalRecord(e, 3, innerIndex) }}></i>
                                                                    </div>
                                                                    <div className='mt-2' key={`${outerIndex}_${innerIndex}`} style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', width: '38rem', height: '3rem', backgroundColor: '#F2F2F2', }}>
                                                                        <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', marginTop: '0.7rem', }}>{record?.name}</div>

                                                                    </div>
                                                                </div>
                                                            ) : ''
                                                        ))
                                                    ))
                                                }
                                            </RenderIf>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mt-1" style={{ paddingLeft: '5px' }}>
                                        <Field name="premedicalType3"
                                            // label="Medical Record Type"
                                            component={Dropdown}
                                            defaultOption="Select Type"
                                            options={Constants.Dropdowns.FileType}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4" >
                                        <div className='' style={{ marginTop: '5px' }}>
                                            <Field name={`premedicalProviderandFacility4`} placeholder="Write Medical Provider Name" type="text" component={TextInput} />
                                            <span style={{ color: 'red' }}>{error.medicalProviderNames_1}</span>
                                        </div>
                                    </div>
                                    <div className="col-md-4" style={{ paddingTop: '2px', paddingLeft: '5px' }}>
                                        <div className=''>
                                            <RenderIf shouldRender={premedicalRecordFileName[4]?.length}>
                                                <div style={{ marginLeft: '36rem' }}>
                                                    <i className='fa-solid fa-trash' style={{ position: 'absolute', width: '20px', height: '20px', paddingTop: '1.4rem' }} onClick={(e) => deletePreMedicalRecord(e, 4, 0)}></i>
                                                </div>
                                            </RenderIf>
                                            <label htmlFor="prefileInput4" style={{ cursor: 'pointer' }}>
                                                <i className="fa-solid fa-plus" style={{ position: 'absolute', paddingTop: '1.3rem', paddingLeft: '1rem' }}></i>
                                                <div className="fileInputStyle mt-1" style={{ backgroundColor: '#F2F2F2', borderRadius: '5px', height: '3rem', width: '38rem' }}>
                                                    <div style={{ textAlign: 'center', height: '1px', paddingTop: '10px' }}>
                                                        {premedicalRecordFileName[4]?.length ? (premedicalRecordFileName[4][0]?.length > 1 ? premedicalRecordFileName[4]?.filter(item => item !== undefined)[0] : premedicalRecordFileName[4]?.filter(item => item !== undefined)[0]) : 'Upload Medical Record'}
                                                    </div>
                                                </div>
                                            </label>
                                            <Field
                                                type="file"
                                                accept="application/pdf"
                                                id="prefileInput4"
                                                name="prefileInput4"
                                                multiple
                                                style={{ display: 'none' }}
                                                onChange={(e) => onPreMedicalRecordsFile(e)}
                                            />
                                            <RenderIf shouldRender={premedicalRecordFileName[4]?.length}>
                                                {
                                                    premedicalRecordsFile?.filter((item, filterIndex) => filterIndex === 4 && item !== undefined)?.map((values, outerIndex) => (
                                                        values?.slice(1).map((record, innerIndex) => (
                                                            record ? (
                                                                <div>
                                                                    <span style={{ display: 'none' }}>{innerIndex = innerIndex + 1}</span>
                                                                    <div style={{ marginLeft: '36rem' }}>
                                                                        <i className='fa-solid fa-trash' style={{ position: 'absolute', width: '20px', height: '20px', paddingTop: '1.2rem' }} onClick={(e) => { e.stopPropagation(); deletePreMedicalRecord(e, 4, innerIndex) }}></i>
                                                                    </div>
                                                                    <div className='mt-2' key={`${outerIndex}_${innerIndex}`} style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', width: '38rem', height: '3rem', backgroundColor: '#F2F2F2', }}>
                                                                        <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', marginTop: '0.7rem', }}>{record?.name}</div>

                                                                    </div>
                                                                </div>
                                                            ) : ''
                                                        ))
                                                    ))
                                                }
                                            </RenderIf>
                                        </div>
                                    </div>
                                    <div className="col-md-4 mt-1" style={{ paddingLeft: '5px' }}>
                                        <Field name="premedicalType4"
                                            // label="Medical Record Type"
                                            component={Dropdown}
                                            defaultOption="Select Type"
                                            options={Constants.Dropdowns.FileType}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className='mt-2'>
                                            <Field name={`premedicalProviderandFacility5`} placeholder="Write Medical Provider Name" type="text" component={TextInput} ref={medicalProviderName} />
                                            <span style={{ color: 'red' }}>{error.medicalProviderNames_5}</span>
                                        </div>
                                        {/* onInput={(e) => handleInput(e)} */}
                                    </div>
                                    <div className="col-md-4" style={{ paddingLeft: '5px' }}>
                                        <div className=''>
                                            <RenderIf shouldRender={premedicalRecordFileName[5]?.length}>
                                                <div style={{ marginLeft: '36rem' }}>
                                                    <i className='fa-solid fa-trash' style={{ position: 'absolute', width: '20px', height: '20px', paddingTop: '1.2rem' }} onClick={(e) => deletePreMedicalRecord(e, 5, 0)}></i>
                                                </div>
                                            </RenderIf>
                                            <label htmlFor="prefileInput5" style={{ cursor: 'pointer' }}>
                                                <i className="fa-solid fa-plus" style={{ position: 'absolute', paddingTop: '1.3rem', paddingLeft: '1rem' }}></i>
                                                <div className="fileInputStyle mt-2" style={{ backgroundColor: '#F2F2F2', borderRadius: '5px', height: '3rem', width: '38rem' }}>
                                                    <div style={{ textAlign: 'center', height: '1px', paddingTop: '10px' }}>
                                                        {premedicalRecordFileName[5]?.length ? (premedicalRecordFileName[5].length > 1 ? premedicalRecordFileName[5][0] : premedicalRecordFileName[5][0]) : 'Upload Medical Record'}
                                                    </div>
                                                </div>
                                            </label>
                                            <Field
                                                type="file"
                                                accept="application/pdf"
                                                id="prefileInput5"
                                                name="prefileInput5"
                                                multiple
                                                style={{ display: 'none' }}
                                                onChange={(e) => onPreMedicalRecordsFile(e)}
                                            />
                                            <RenderIf shouldRender={premedicalRecordFileName[5]?.length}>
                                                {
                                                    premedicalRecordsFile?.filter((item, filterIndex) => filterIndex === 5 && item !== undefined)?.map((values, outerIndex) => (
                                                        values?.slice(1).map((record, innerIndex) => (
                                                            record ? (
                                                                <div>
                                                                    <div style={{ display: 'none' }}>{innerIndex = innerIndex + 1}</div>
                                                                    <div style={{ marginLeft: '36rem' }}>
                                                                        <i className='fa-solid fa-trash' style={{ position: 'absolute', width: '20px', height: '20px', paddingTop: '1.2rem' }} onClick={(e) => deleteMedicalRecord(e, 5, innerIndex)}></i>
                                                                    </div>
                                                                    <div className='mt-2' key={`${outerIndex}_${innerIndex}`} style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', width: '38rem', height: '3rem', backgroundColor: '#F2F2F2', }}>
                                                                        <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', marginTop: '0.7rem', }}>{record?.name}</div>
                                                                    </div>
                                                                </div>
                                                            ) : ''
                                                        ))
                                                    ))
                                                }
                                            </RenderIf>
                                        </div>
                                    </div>

                                    <div className="col-md-3 mt-2">
                                        <button className='preMedicalProvider-add-icon' onClick={(e) => onPreMedicalClickAddBtn(e)} style={{ position: 'absolute', marginLeft: '32rem', backgroundColor: '#18479a', borderRadius: '4px', height: '3rem', width: '5rem' }}><i className="fa-solid fa-plus"></i></button>
                                        <Field name="premedicalType5"
                                            component={Dropdown}
                                            defaultOption="Select Type"
                                            options={Constants.Dropdowns.FileType}
                                            extendWidth={true}
                                        />
                                    </div>
                                </div>

                                <RenderIf shouldRender={premedicalProviderCounter}>
                                    <PreMedicalProvidersControl
                                        data={premedicalProviderCounter}
                                        handleInputPreMeicalProviderandFacitlityName={(e, innerIndex) => handleInputPreMeicalProviderandFacitlityName(e, innerIndex)}
                                        onClickPreMedicalMinusBtn={(e, value, index, innerIndex) => onClickPreMedicalMinusBtn(e, value, index, innerIndex)}
                                        premedicalRecordFileName={premedicalRecordFileName}
                                        onPreMedicalRecordsFile={onPreMedicalRecordsFile}
                                        premedicalRecordsFile={premedicalRecordsFile}
                                        error={error}
                                        deletePreMedicalRecord={(e, outerIndex, innerIndex) => deletePreMedicalRecord(e, outerIndex, innerIndex)}
                                    />
                                </RenderIf>

                            </div>
                        </div>

                        <div className="btns text-center mt-4">
                            <button className="btn-theme btn-outline me-3" onClick={async () => {
                                setIsSubmitting(true);
                                await submitForm();
                                stepDecrement();
                            }}>Previous</button>
                            <button className="btn-theme" type="submit">Next</button>
                        </div>

                    </Form >
                )
                }
            </Formik >



        </>
    )

}



const MedicalProvidersControl = ({ data, handleInput, onClickMinusBtn, onMedicalBillFileSelect, medicalBillName, medicalRecordFileName, onMedicalRecordsFile, error, openMedicalFileModal, openMedicalBillModal,
    multipleMedicalProviderandFacitlityName,
    deleteMedicalBill,
    deleteMedicalRecord,
    medicalRecordsFile,
}) => {
    const InjuryData = useSelector(state => state.injuryData.injuryData);

    const removeMedicalProvider = (e, value, index, innerIndex, medTypeIndex) => {
        e.preventDefault();
        onClickMinusBtn(e, value, index, innerIndex, medTypeIndex)
    };

    let innerIndex
    return (
        <>
            <RenderIf shouldRender={data.length}>

                {
                    data.length && data.map((value, index) => (
                        <>
                            {/* {console.log(value, index + 5)} */}
                            <span style={{ display: 'none' }}>{innerIndex = index}{index = index + 6}{value} </span>
                            {/* <span >{innerIndex = index}{index = index + 6} </span> */}
                            <div className="row">
                                <div className="col-md-3" >
                                    <div className='mt-3'>
                                        <Field name={`medicalProviderandFacility${index}`} placeholder="Write Medical Provider Name" type="text" component={TextInput} />

                                        <span style={{ color: 'red' }}>{error.medicalProviderNames_5}</span>
                                    </div>
                                    {/* onInput={(e) => handleInput(e)} */}
                                </div>
                                <div className="col-md-3" style={{ paddingLeft: '5px' }}>
                                    <div className='mt-2'>
                                        <RenderIf shouldRender={medicalRecordFileName[index]?.length}>
                                            <div style={{ marginLeft: '26rem' }}>
                                                <i className='fa-solid fa-trash' style={{ position: 'absolute', width: '20px', height: '20px', paddingTop: '1.5rem' }} onClick={(e) => { deleteMedicalRecord(e, index, 0) }}></i>
                                            </div>
                                        </RenderIf>
                                        <label htmlFor={`fileInput${index}`} style={{ cursor: 'pointer' }}>
                                            <i className="fa-solid fa-plus" style={{ position: 'absolute', paddingTop: '1.3rem', paddingLeft: '1rem' }}></i>
                                            <div className="fileInputStyle mt-2" style={{ backgroundColor: '#F2F2F2', borderRadius: '5px', height: '3rem', width: '28rem' }}>
                                                <div style={{ textAlign: 'center', height: '1px', paddingTop: '10px' }}>
                                                    {medicalRecordFileName[index]?.length ? (medicalRecordFileName[index].length > 1 ? medicalRecordFileName[index][0] : medicalRecordFileName[index][0]) : 'Upload Medical Record'}    </div>
                                            </div>
                                        </label>
                                        <Field
                                            type="file"
                                            accept="application/pdf"
                                            id={`fileInput${index}`}
                                            name={`fileInput${index}`}
                                            multiple
                                            style={{ display: 'none' }}
                                            onChange={(e) => onMedicalRecordsFile(e)}
                                        />
                                        <RenderIf shouldRender={medicalRecordFileName[index]?.length}>
                                            {
                                                medicalRecordsFile?.filter((item, filterIndex) => filterIndex === index && item !== undefined)?.map((values, outerIndex) => (
                                                    values?.slice(1).map((record, innerIndex) => (
                                                        record ? (
                                                            <div>
                                                                <div style={{ marginLeft: '26rem' }}>
                                                                    <div style={{ display: 'none' }}>{innerIndex = innerIndex + 1}</div>
                                                                    <i className='fa-solid fa-trash' style={{ position: 'absolute', width: '20px', height: '20px', paddingTop: '1.5rem' }} onClick={(e) => deleteMedicalRecord(e, index, innerIndex)}></i>
                                                                </div>
                                                                <div className='mt-2' key={`${outerIndex}_${innerIndex}`} style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', width: '28rem', height: '3rem', backgroundColor: '#F2F2F2', }}>

                                                                    <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', marginTop: '0.7rem', }}>{record?.name}</div>
                                                                </div>
                                                            </div>
                                                        ) : ''
                                                    ))
                                                ))
                                            }
                                        </RenderIf>
                                    </div>
                                </div>
                                <div className="col-md-3 mt-3" style={{ paddingLeft: '5px' }}>
                                    <Field name={`medicalType${index}`}
                                        // label="Medical Record Type"
                                        component={Dropdown}
                                        defaultOption="Select Type"
                                        options={Constants.Dropdowns.FileType}
                                    />
                                </div>
                                <div className="col-md-3" style={{ paddingLeft: '5px' }}>
                                    <RenderIf shouldRender={medicalBillName[index]?.length}>
                                        <div style={{ marginLeft: '21rem' }}>
                                            <i className='fa-solid fa-trash' style={{ position: 'absolute', width: '20px', height: '20px', paddingTop: '2rem' }} onClick={(e) => { deleteMedicalBill(e, index, 0) }}></i>
                                        </div>
                                    </RenderIf>
                                    <div className='mt-2' style={{ display: 'flex' }}>
                                        <label htmlFor={`fileInputBill${index}`} style={{ cursor: 'pointer' }}>
                                            <i className="fa-solid fa-plus" style={{ position: 'absolute', paddingTop: '1.3rem', paddingLeft: '1rem' }}></i>
                                            <div className="fileInputStyle mt-2" style={{ backgroundColor: '#F2F2F2', borderRadius: '5px', height: '3rem', width: '23rem' }}>
                                                <div style={{ textAlign: 'center', height: '1px', paddingTop: '10px' }}>
                                                    {medicalBillName[index]?.length ? (medicalBillName[index].length > 1 ? medicalBillName[index][0] : medicalBillName[index][0]) : 'Upload Medical Bill'}
                                                </div>
                                            </div>
                                        </label>
                                        <Field
                                            type="file"
                                            accept="application/pdf"
                                            id={`fileInputBill${index}`}
                                            name={`fileInputBill${index}`}
                                            multiple
                                            style={{ display: 'none' }}
                                            onChange={(e) => onMedicalBillFileSelect(e)}
                                        />
                                        <div className='add-icon' style={{ marginLeft: '1rem' }}>
                                            <button className='add-btn-style' onClick={(e) => removeMedicalProvider(e, value, index, innerIndex, `medicalType${index}`)}><i className="fa-solid fa-minus"></i></button>  </div>
                                    </div>

                                    <RenderIf shouldRender={medicalBillName[index]?.length}>
                                        {
                                            medicalBillName?.filter((item, filterIndex) => filterIndex === index && item !== undefined)?.map((values, outerIndex) => (
                                                values?.slice(1).map((record, innerIndex) => (
                                                    record ? (
                                                        <div>
                                                            <div style={{ marginLeft: '26rem' }}>
                                                                <div style={{ display: 'none' }}>{innerIndex = innerIndex + 1}</div>
                                                                <i className='fa-solid fa-trash' style={{ position: 'absolute', width: '20px', height: '20px', paddingTop: '1.1rem' }} onClick={(e) => deleteMedicalBill(e, index, innerIndex)}></i>
                                                            </div>
                                                            <div className='mt-2' key={`${outerIndex}_${innerIndex}`} style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', width: '28rem', height: '3rem', backgroundColor: '#F2F2F2', }}>

                                                                <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', marginTop: '0.7rem', }}>{record}</div>
                                                            </div>
                                                        </div>
                                                    ) : ''
                                                ))
                                            ))
                                        }
                                    </RenderIf>
                                </div>

                            </div>

                        </>
                    ))

                }
            </RenderIf>
        </>
    )
}


const PreMedicalProvidersControl = ({ data, handleInputPreMeicalProviderandFacitlityName, onClickPreMedicalMinusBtn, premedicalRecordFileName, onPreMedicalRecordsFile, error, deletePreMedicalRecord, premedicalRecordsFile
}) => {

    const removeMedicalProvider = (e, value, index, innerIndex) => {
        e.preventDefault();
        onClickPreMedicalMinusBtn(e, value, index, innerIndex)
    };

    let innerIndex
    return (
        <>
            <RenderIf shouldRender={data.length}>
                {
                    data.length && data.map((value, index) => (
                        <>
                            {/* {console.log(value, index + 7)} */}
                            <span style={{ display: 'none' }}>{innerIndex = index}{index = index + 6}{value} </span>
                            {/* <span >{innerIndex = index}{index = index + 6} </span> */}

                            <div className="row">
                                <div className="col-md-4" >
                                    <div className='mt-3'>
                                        <Field name={`premedicalProviderandFacility${index}`} placeholder="Write Medical Provider Name" type="text" component={TextInput} />
                                    </div>
                                    {/* onInput={(e) => handleInput(e)} */}
                                </div>
                                <div className="col-md-4" style={{ paddingLeft: '5px' }}>
                                    <div className='mt-2'>
                                        <RenderIf shouldRender={premedicalRecordFileName[index]?.length}>
                                            <div style={{ marginLeft: '36rem' }}>
                                                <i className='fa-solid fa-trash' style={{ position: 'absolute', width: '20px', height: '20px', paddingTop: '1.5rem' }} onClick={(e) => { deletePreMedicalRecord(e, index, 0) }}></i>
                                            </div>
                                        </RenderIf>
                                        <label htmlFor={`prefileInput${index}`} style={{ cursor: 'pointer' }}>
                                            <i className="fa-solid fa-plus" style={{ position: 'absolute', paddingTop: '1.3rem', paddingLeft: '1rem' }}></i>
                                            <div className="fileInputStyle mt-2" style={{ backgroundColor: '#F2F2F2', borderRadius: '5px', height: '3rem', width: '38rem' }}>
                                                <div style={{ textAlign: 'center', height: '1px', paddingTop: '10px' }}>
                                                    {premedicalRecordFileName[index]?.length ? (premedicalRecordFileName[index].length > 1 ? premedicalRecordFileName[index][0] : premedicalRecordFileName[index][0]) : 'Upload Medical Record'}    </div>
                                            </div>
                                        </label>
                                        <Field
                                            type="file"
                                            accept="application/pdf"
                                            id={`prefileInput${index}`}
                                            name={`prefileInput${index}`}
                                            multiple
                                            style={{ display: 'none' }}
                                            onChange={(e) => onPreMedicalRecordsFile(e)}
                                        />
                                        <RenderIf shouldRender={premedicalRecordFileName[index]?.length}>
                                            {
                                                premedicalRecordsFile?.filter((item, filterIndex) => filterIndex === index && item !== undefined)?.map((values, outerIndex) => (
                                                    values?.slice(1).map((record, innerIndex) => (
                                                        record ? (
                                                            <div>
                                                                <div style={{ marginLeft: '36rem' }}>
                                                                    <div style={{ display: 'none' }}>{innerIndex = innerIndex + 1}</div>
                                                                    <i className='fa-solid fa-trash' style={{ position: 'absolute', width: '20px', height: '20px', paddingTop: '1rem' }} onClick={(e) => deletePreMedicalRecord(e, index, innerIndex)}></i>
                                                                </div>
                                                                <div className='mt-2' key={`${outerIndex}_${innerIndex}`} style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', width: '38rem', height: '3rem', backgroundColor: '#F2F2F2', }}>

                                                                    <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center', marginTop: '0.7rem', }}>{record?.name}</div>
                                                                </div>
                                                            </div>
                                                        ) : ''
                                                    ))
                                                ))
                                            }
                                        </RenderIf>
                                    </div>
                                </div>
                                <div className="col-md-4 mt-3" style={{ paddingLeft: '4px' }}>
                                    <button className='preMedicalProvider-add-icon' onClick={(e) => removeMedicalProvider(e, value, index, innerIndex)} style={{ position: 'absolute', marginLeft: '32.3rem', backgroundColor: '#18479a', borderRadius: '4px', height: '3rem', width: '5rem' }}><i className="fa-solid fa-minus"></i></button>
                                    <Field name={`premedicalType${index}`}
                                        // label="Medical Record Type"
                                        component={Dropdown}
                                        defaultOption="Select Type"
                                        options={Constants.Dropdowns.FileType}
                                    />
                                </div>
                            </div>


                        </>
                    ))

                }
            </RenderIf>
        </>
    )

}



export default InjuriesAnalysisForm;