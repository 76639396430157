import * as type from '../type';

const initialState = {
    injuryData: {},
}

export default function injuryData(state = initialState, action) {
  switch (action.type) {
    case type.STORED_INJURY_ANALYSIS:
      return {
        ...state,
        injuryData: action.payload
      }
    default:
      return state
  }
}